import React, { useState } from 'react';
import { Table, Button, Input, Space, Card, Select, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './CampaignDashboard.css';

const { Search } = Input;
const { Option } = Select;

const CampaignDashboard = () => {
  const [campaigns, setCampaigns] = useState([
    { key: '1', name: 'Summer Sale', category: 'Promotion', status: 'Active', sent: 1500, opened: 1200, lastUpdated: '2024-08-01' },
    { key: '2', name: 'Winter Promotion', category: 'Promotion', status: 'Completed', sent: 2000, opened: 1600, lastUpdated: '2024-07-20' },
    // Add more campaigns as needed
  ]);

  const navigate = useNavigate();

  const columns = [
    { title: 'Campaign Name', dataIndex: 'name', key: 'name' },
    { title: 'Category', dataIndex: 'category', key: 'category' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Messages Sent', dataIndex: 'sent', key: 'sent' },
    { title: 'Messages Opened', dataIndex: 'opened', key: 'opened' },
    { title: 'Last Updated', dataIndex: 'lastUpdated', key: 'lastUpdated' },
  ];

  const handleCreateCampaign = () => {
    navigate('/create-campaign');
  };

  return (
    <div className="campaign-dashboard">
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <Space style={{ marginBottom: 16 }}>
              <Search placeholder="Search campaigns" onSearch={(value) => console.log(value)} style={{ width: 200 }} />
              <Select defaultValue="Category" style={{ width: 120 }}>
                <Option value="promotion">Promotion</Option>
                <Option value="informational">Informational</Option>
                <Option value="event">Event</Option>
              </Select>
              <Select defaultValue="Status" style={{ width: 120 }}>
                <Option value="active">Active</Option>
                <Option value="completed">Completed</Option>
                <Option value="upcoming">Upcoming</Option>
              </Select>
              <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateCampaign}>
                Create Campaign
              </Button>
            </Space>
            <Table columns={columns} dataSource={campaigns} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CampaignDashboard;
