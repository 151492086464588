import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const EditModal = ({ visible, onClose, onSave, booking }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (booking) {
      form.setFieldsValue({
        customerName: booking.customerName,
        phoneNumber: booking.phoneNumber,  // Adjusted to use customerPhoneNumber
        pickupLocation: booking.pickupLocation,
        dropLocation: booking.dropLocation,
        status: booking.status,  // Updated to use single status field
      });
    }
  }, [booking, form]);

  const handleFormSubmit = (values) => {
    onSave(values);
  };

  return (
    <Modal
      title="Edit Booking"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <Form.Item
          label="Customer Name"
          name="customerName"
          rules={[{ required: true, message: 'Please enter the customer name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[{ required: true, message: 'Please enter the phone number' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Pickup Location"
          name="pickupLocation"
          rules={[{ required: true, message: 'Please enter the pickup location' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Drop Location"
          name="dropLocation"
          rules={[{ required: true, message: 'Please enter the drop location' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select the booking status' }]}
        >
          <Select>
            <Option value="Pending">Pending</Option>
            <Option value="Booked">Booked</Option>
            <Option value="Driver Assigned">Driver Assigned</Option>
            <Option value="Trip Started">Trip Started</Option>
            <Option value="Trip Completed">Trip Completed</Option>
            <Option value="Trip Canceled">Trip Canceled</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
