import React from 'react';
import { Card, Row, Col, Statistic, Divider } from 'antd';
import { WhatsAppOutlined, MailOutlined, FacebookOutlined, InstagramOutlined, GoogleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './AdminMarketingPage.css'; // Ensure this CSS file exists and is correctly linked

const AdminMarketingPage = () => {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <div className="admin-marketing-page">
      <h1>Marketing Dashboard</h1>
      <Row gutter={[16, 16]} justify="start">
        {/* Key Performance Indicators */}
        <Col span={24}>
          <Card className="overview-card">
            <Row justify="space-between">
              <Col span={4}>
                <Statistic title="Total Revenue" value={35000} prefix="$" />
              </Col>
              <Col span={4}>
                <Statistic title="Conversion Rate" value={4.5} suffix="%" />
              </Col>
              <Col span={4}>
                <Statistic title="Click-Through Rate" value={2.7} suffix="%" />
              </Col>
              <Col span={4}>
                <Statistic title="Customer Growth" value={10.2} suffix="%" />
              </Col>
              <Col span={4}>
                <Statistic title="New Customers" value={150} />
              </Col>
            </Row>
          </Card>
        </Col>

        <Divider />

        {/* Individual Marketing Channels */}
        <Col span={4}>
          <Card
            className="marketing-card"
            hoverable
            onClick={() => handleCardClick('/whatsapp-dashboard')}
          >
            <WhatsAppOutlined style={{ fontSize: '48px', color: '#25D366' }} />
            <p>WhatsApp Dashboard</p>
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="marketing-card"
            hoverable
            onClick={() => handleCardClick('/email-dashboard')}
          >
            <MailOutlined style={{ fontSize: '48px', color: '#007bff' }} />
            <p>Email Campaigns</p>
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="marketing-card"
            hoverable
            onClick={() => handleCardClick('/facebook-dashboard')}
          >
            <FacebookOutlined style={{ fontSize: '48px', color: '#3b5998' }} />
            <p>Facebook Marketing</p>
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="marketing-card"
            hoverable
            onClick={() => handleCardClick('/instagram-dashboard')}
          >
            <InstagramOutlined style={{ fontSize: '48px', color: '#e4405f' }} />
            <p>Instagram Ads</p>
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="marketing-card"
            hoverable
            onClick={() => handleCardClick('/google-ads-dashboard')}
          >
            <GoogleOutlined style={{ fontSize: '48px', color: '#DB4437' }} />
            <p>Google Ads</p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminMarketingPage;
