// CustomerReviews.js
import React from 'react';
import Slider from 'react-slick';
import { Card, Rate, Typography } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import './CustomerReviews.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { Title, Paragraph } = Typography;


const reviews = [
  {
    name: 'John Doe',
    rating: 5,
    comment: 'Great service! Highly recommend.',
    photo: 'https://randomuser.me/api/portraits/men/1.jpg',
  },
  {
    name: 'Jane Smith',
    rating: 4,
    comment: 'Very efficient and reliable.',
    photo: 'https://randomuser.me/api/portraits/women/2.jpg',
  },
  {
    name: 'Sam Wilson',
    rating: 5,
    comment: 'Fantastic experience. Will use again.',
    photo: 'https://randomuser.me/api/portraits/men/3.jpg',
  },
];

const CustomerReviews = () => {
  const settings = {
    dots: true, // Hide dots for a cleaner look
    infinite: true,
    speed: 15000, // Adjust the speed to control how fast the slides move
    slidesToShow: 4,
    slidesToScroll: 99,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for continuous sliding
    cssEase: 'linear', // Use linear easing for a smooth, continuous effect
    pauseOnHover: false, // Optional: Prevent pausing on hover for a true continuous effect
  };

  return (
    <div className="customer-reviews">
      <Title level={3} className='customer-reviews-title'>What our customers are saying: </Title>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-slide">
            <Card className="review-card">
              <img src={review.photo} alt={review.name} className="review-photo" />
              <Rate disabled defaultValue={review.rating} />
              <p className="review-comment">{review.comment}</p>
              <p className="review-name"><SmileOutlined /> {review.name}</p>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomerReviews;
