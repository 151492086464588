import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Card, Row, Col, Badge, Button, DatePicker, message } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, SafetyOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import hatchbackImage from '../assets/hatchback.png';
import sedanImage from '../assets/sedan.png';
import suvImage from '../assets/innova.png';
import './BookingDetailsPage.css';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { createOrder } from '../../services/api';


const { Title, Paragraph, Text } = Typography;

const DAILY_VEHICLE_RENT_COST = 1000; // Daily rent cost for the vehicle including driver expenses


const BookingDetailsPage = () => {
    // useEffect(loadScript,[])

  const location = useLocation();
  const { bookingDetails } = location.state || {};
  const navigate = useNavigate();

  const [departureDate, setDepartureDate] = useState(moment());
  const [returnDate, setReturnDate] = useState(null);
  const [prices, setPrices] = useState({
    hatchback: Math.round(bookingDetails.totalCost.hatchback),
    sedan: Math.round(bookingDetails.totalCost.sedan),
    suv: Math.round(bookingDetails.totalCost.suv),
  });


  const handleBooking = async (amount, name, description) => {

    console.log("Amount is : ", amount)
    const createOrderRequest = {
        'amount': amount
    }
    const order = await createOrder(createOrderRequest)


    console.log("Order Details: ", order);

    const options = {
      key: "rzp_live_OP9Fkn4pgh6AYx", // Replace with your key ID
      amount: 1 * 100, // Amount in paise
      currency: "INR",
      name: name,
      description: description,
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: function (response) {
        console.log(response)
        navigate('/payment-success', {
          state: {
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
          },
        });
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      navigate('/payment-failure', {
        state: {
          errorCode: response.error.code,
          description: response.error.description,
          source: response.error.source,
          step: response.error.step,
          reason: response.error.reason,
          orderId: response.error.metadata.order_id,
          paymentId: response.error.metadata.payment_id,
        },
      });
    });

    rzp1.open();
  };


//   const handleBookNowClick = () => {
//     window.open("https://pages.razorpay.com/citytaxisbooking", "_blank");
// };

  useEffect(() => {
    if (returnDate && returnDate.isBefore(departureDate)) {
      message.error('Return date cannot be before the departure date.');
      setReturnDate(null);
      setPrices({
        hatchback: Math.round(bookingDetails.totalCost.hatchback),
        sedan: Math.round(bookingDetails.totalCost.sedan),
        suv: Math.round(bookingDetails.totalCost.suv),
      });
    } else if (returnDate) {
      const days = returnDate.diff(departureDate, 'days') + 1; // Include both departure and return days
      setPrices({
        hatchback: Math.round((bookingDetails.totalCost.hatchback + DAILY_VEHICLE_RENT_COST) * days),
        sedan: Math.round((bookingDetails.totalCost.sedan + DAILY_VEHICLE_RENT_COST) * days),
        suv: Math.round((bookingDetails.totalCost.suv + DAILY_VEHICLE_RENT_COST) * days),
      });
    } else {
      setPrices({
        hatchback: Math.round(bookingDetails.totalCost.hatchback + DAILY_VEHICLE_RENT_COST),
        sedan: Math.round(bookingDetails.totalCost.sedan + DAILY_VEHICLE_RENT_COST),
        suv: Math.round(bookingDetails.totalCost.suv + DAILY_VEHICLE_RENT_COST),
      });
    }
  }, [departureDate, returnDate]);

  const handleDepartureDateChange = (date) => {
    if (returnDate && date.isAfter(returnDate)) {
      message.error('Departure date cannot be after the return date.');
      return;
    }
    setDepartureDate(date);
  };

  const handleReturnDateChange = (date) => {
    if (date && date.isBefore(departureDate)) {
      message.error('Return date cannot be before the departure date.');
      return;
    }
    setReturnDate(date);
  };

  if (!bookingDetails) {
    return <div>No booking details available.</div>;
  }

  const totalDays = returnDate ? returnDate.diff(departureDate, 'days') + 1 : 1;

  return (
    <div className="booking-details-container">
      <div className="header">
        <ArrowLeftOutlined className="back-icon" onClick={()=>
                navigate('/') // Navigate to new page with booking details
        } />
        <Text className="itinerary-text">
          <span>{bookingDetails.itinerary[0]}</span>
          <br />
          <span>&rarr;</span>
          <br />
          <span>{bookingDetails.itinerary[1]}</span>
        </Text>
        <div className="trip-type">{returnDate ? 'Round Trip' : 'One Way'}</div>
      </div>
      <div className="date-section">
        <div className="date-item">
          <Title level={5}>Departure</Title>
          <DatePicker
            value={departureDate}
            onChange={handleDepartureDateChange}
            className="booking-date-picker"
          />
        </div>
        <div className="date-item">
          <Title level={5}>Return</Title>
          <DatePicker
            value={returnDate}
            onChange={handleReturnDateChange}
            className="booking-date-picker"
          />
        </div>
        {/* <div className="date-item">
          <Title level={5}>Total Days</Title>
          <Text>{totalDays}</Text>
        </div> */}
      </div>
      <div className="features-section">
        <div className="feature-item">
          <SafetyOutlined />
          <Text>Safety First</Text>
        </div>
        <div className="feature-item">
          <CheckCircleOutlined />
          <Text>Confirmed Cab</Text>
        </div>
        <div className="feature-item">
          <ClockCircleOutlined />
          <Text>Always On-time</Text>
        </div>
      </div>
      <Row gutter={16} justify="center">
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card className="vehicle-card">
            <Badge.Ribbon text="50% OFF" color="red">
              <div className="vehicle-image">
                <img src={hatchbackImage} alt="Hatchback" className="car-image" />
              </div>
              <Title level={3} className="price-title">₹ {prices.hatchback}</Title>
              <Text className="vehicle-type">Hatchback (AC)</Text>
              <Paragraph className="vehicle-description">Swift, WagonR or Similar</Paragraph>
              <Paragraph className="vehicle-details">
                Included Km: <strong>250 Km</strong><br />
                Extra fare/Km: <strong>₹ 11.2/Km</strong><br />
                Driver Charges: <strong>Included</strong><br />
                Night Charges: <strong>Included</strong><br />
                <a href="#">Other Terms</a>
              </Paragraph>
              <Button type="primary" block className="book-now-button" 
              onClick={() => (handleBooking(prices.hatchback, 'SUV (AC)', 'Pay 200 now and the rest can be paid to driver. Innova, WagonR or Similar'))}
              >Book Now</Button>
            </Badge.Ribbon>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card className="vehicle-card">
            <Badge.Ribbon text="50% OFF" color="red">
              <div className="vehicle-image">
                <img src={sedanImage} alt="Sedan" className="car-image" />
              </div>
              <Title level={3} className="price-title">₹ {prices.sedan}</Title>
              <Text className="vehicle-type">Sedan (AC)</Text>
              <Paragraph className="vehicle-description">Dzire, Etios or Similar</Paragraph>
              <Paragraph className="vehicle-details">
                Included Km: <strong>250 Km</strong><br />
                Extra fare/Km: <strong>₹ 11.2/Km</strong><br />
                Driver Charges: <strong>Included</strong><br />
                Night Charges: <strong>Included</strong><br />
                <a href="#">Other Terms</a>
              </Paragraph>
              <Button type="primary" block className="book-now-button" 
              onClick={() => (handleBooking(299, 'Sedan (AC)', 'Swift, Ciaz or Similar'))}
              >
                Book Now</Button>
            </Badge.Ribbon>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card className="vehicle-card">
            <Badge.Ribbon text="50% OFF" color="red">
              <div className="vehicle-image">
                <img src={suvImage} alt="SUV" className="car-image" />
              </div>
              <Title level={3} className="price-title">₹ {prices.suv}</Title>
              <Text className="vehicle-type">SUV (AC)</Text>
              <Paragraph className="vehicle-description">Innova, Ertiga, Marazzo or Similar</Paragraph>
              <Paragraph className="vehicle-details">
                Included Km: <strong>250 Km</strong><br />
                Extra fare/Km: <strong>₹ 15.8/Km</strong><br />
                Driver Charges: <strong>Included</strong><br />
                Night Charges: <strong>Included</strong><br />
                <a href="#">Other Terms</a>
              </Paragraph>
              <Button type="primary" block className="book-now-button" 
            //   onClick={handleBookNowClick}
              onClick={() => (handleBooking(299, 'SUV (AC) - Booking Advance', 'Pay 200 now and the rest can be paid to driver. Innova, WagonR or Similar'))}
              >Book Now</Button>
            </Badge.Ribbon>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BookingDetailsPage;
