import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Form, Input, message, Typography, Divider } from 'antd';
import { getAllBookings, sendWhatsAppMessage } from '../../../services/api';

const { Option } = Select;
const { Text, Paragraph } = Typography;

const SendTemplateModal = ({ watemplate, isVisible, onClose }) => {
    const [users, setUsers] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const fetchedUsers = await getAllBookings();
                setUsers(fetchedUsers);
                console.log('Fetched users:', fetchedUsers); // Log fetched users
            } catch (error) {
                console.error("Failed to fetch users:", error);
                message.error("Failed to fetch users: " + error.message);
            }
        };

        if (isVisible) {
            loadUsers();
            form.resetFields(); // Reset form fields when modal is opened
        }
    }, [isVisible, form]);

    const handleSubmit = async () => {
        try {
            console.log('Attempting to submit form...');
            const values = await form.validateFields();
            console.log('Form values:', values);
    
            // Construct the template message content
            const messageContent = {
                name: watemplate.name, // Template name
                language: { code: watemplate.language }, // Assuming the default language
                components: watemplate.components.map(comp => {
                    if (comp.type === 'HEADER' && comp.format === 'IMAGE') {
                        return {
                            type: 'header',
                            parameters: [{
                                type: 'image',
                                image: {
                                    link: comp.example.header_handle[0] // Ensure this is the correct image URL
                                }
                            }]
                        };
                    }
                    if (comp.type === 'BODY' && comp.text.includes('{{')) {
                        return {
                            type: 'body',
                            parameters: comp.text.match(/{{\d+}}/g).map(match => ({
                                type: 'text',
                                text: values[`variable${match.replace(/[{}]/g, '')}`]
                            }))
                        };
                    }
                    if (comp.type === 'BUTTONS') {
                        return comp.buttons.map((button, idx) => {
                            if (button.type === 'Url') {
                                return {
                                    type: 'button',
                                    sub_type: 'url',
                                    index: idx,
                                    parameters: []
                                };
                            } else if (button.type === 'QUICK_REPLY') {
                                return {
                                    type: 'button',
                                    sub_type: 'QUICK_REPLY',
                                    index: idx,
                                    parameters: [{
                                        type: 'payload',
                                        payload: button.payload // Payload for quick reply
                                    }]
                                };
                            } else if (button.type === 'CALL') {
                                // For call type buttons
                                return {
                                    type: 'button',
                                    sub_type: 'CALL',
                                    index: idx,
                                    parameters: [{
                                        type: 'text',
                                        text: button.phoneNumber // The phone number
                                    }]
                                };
                            }
                            return null; // Ignore unsupported types
                        });
                    }
                    return null;
                }).flat().filter(component => component !== null)
            };
    
            console.log('Message content:', messageContent);
    
            const sendMessageRequest = {
                messaging_product: 'whatsapp',
                to: '91' + values.userId,
                messageType: 'template',
                messageContent: messageContent
            };
    
            await sendWhatsAppMessage(sendMessageRequest);
            message.success("Template sent successfully!");
            onClose();
        } catch (error) {
            console.error('Submit error:', error);
            if (error.errorFields) {
                error.errorFields.forEach(fieldError => {
                    console.error('Field Error:', fieldError);
                });
            }
            message.error("Failed to send template: " + error.message);
        }
    };
    
    
    // const sendWhatsappMessage = async (to, messageType, messageContent) => {
    //     const url = 'https://graph.facebook.com/v20.0/190343557490134/messages';
    //     const token = 'YOUR_ACCESS_TOKEN'; // Replace with your actual token
    
    //     console.log("Service: ", to, messageContent, messageType);
    
    //     const data = {
    //         messaging_product: 'whatsapp',
    //         to: to,
    //         type: messageType,
    //         template: {
    //             name: messageContent.name,
    //             language: messageContent.language,
    //             components: messageContent.components
    //         }
    //     };
    
    //     try {
    //         const response = await axios.post(url, data, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    
    //         return response.data;
    //     } catch (error) {
    //         console.error('Failed to send WhatsApp message:', error.response ? error.response.data : error.message);
    //         throw new Error('Failed to send WhatsApp message');
    //     }
    // };
    

    // Function to render the preview of the template
    const renderTemplatePreview = () => {
        if (!watemplate) return <h2>Hello 2</h2>;
        
        return (
            <div>
                <h1>Hello</h1>
                {watemplate.components.map((component, index) => (
                    <div key={index} style={{ marginBottom: '16px' }}>
                        {component.type === 'HEADER' && component.format === 'TEXT' && (
                            <Paragraph><Text strong>Header:</Text> {component.text}</Paragraph>
                        )}
                        {component.type === 'BODY' && (
                            <Paragraph><Text strong>Body:</Text> {component.text}</Paragraph>
                        )}
                        {component.type === 'BUTTONS' && component.buttons.map((button, idx) => (
                            <Button key={idx} style={{ marginRight: '8px' }}>{button.text}</Button>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Modal
            title={`Send Template: ${watemplate?.name || 'Loading...'}`}
            visible={isVisible}
            onCancel={onClose}
            onOk={handleSubmit}
            okText="Send"
            cancelText="Cancel"
            width={600} // Adjust width of the modal to make it bigger
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="userId"
                    label="Select User"
                    rules={[{ required: true, message: 'Please select a user!' }]}
                >
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {users.map(user => (
                            <Option key={user._id} value={user.phoneNumber}>
                                {`${user.customerName} (${user.phoneNumber})`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {watemplate?.components.map((component, index) => (
                    component.type === 'BODY' && component.text.includes('{{') ? (
                        component.text.match(/{{\d+}}/g).map((match, idx) => (
                            <Form.Item
                                key={`${index}-${idx}`}
                                name={`variable${match.replace(/[{}]/g, '')}`}
                                label={`Variable ${match}`}
                                rules={[{ required: true, message: `Please input value for ${match}` }]}
                            >
                                <Input placeholder={`Enter value for ${match}`} />
                            </Form.Item>
                        ))
                    ) : null
                ))}
                <Divider />
                <div>
                    <Text strong>Template Preview:</Text>
                    {renderTemplatePreview()}
                </div>
            </Form>
        </Modal>
    );
};

export default SendTemplateModal;
