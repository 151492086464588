/* global FB */

import React, { useEffect, useState } from 'react';
import { Button, Table, message } from 'antd';
import axios from 'axios';

const WhatsAppProviderHomePage = () => {
  const [wabas, setWabas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    // Load the Facebook JavaScript SDK
    window.fbAsyncInit = function() {
      FB.init({
        appId: '1073295390519152',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v20.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const launchWhatsAppSignup = () => {
    FB.login(function(response) {
      if (response.authResponse) {
        const token = response.authResponse.accessToken;
        setAccessToken(token);
        message.success('Login successful! Retrieving WABA ID...');
        fetchWABAID(token);
      } else {
        message.error('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '<CONFIG_ID>', // configuration ID obtained in the previous step goes here
      response_type: 'code', // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {
          // Prefilled data can go here
        }
      }
    });
  };

  const fetchWABAID = async (token) => {
    try {
      const response = await axios.get(`https://graph.facebook.com/v20.0/debug_token?input_token=${token}`, {
        headers: {
          'Authorization': `Bearer YOUR_SYSTEM_USER_ACCESS_TOKEN`
        }
      });
      const wabaID = response.data.data.granular_scopes.find(scope => scope.scope === 'whatsapp_business_management').target_ids[0];
      message.success(`WABA ID retrieved: ${wabaID}`);
      fetchWABAs(wabaID);
    } catch (error) {
      message.error('Failed to retrieve WABA ID');
      console.error(error);
    }
  };

  const fetchWABAs = async (wabaID) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://graph.facebook.com/v20.0/${wabaID}/client_whatsapp_business_accounts`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setWabas(response.data.data);
      message.success('WABAs retrieved successfully!');
    } catch (error) {
      message.error('Failed to retrieve WABAs');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Timezone ID',
      dataIndex: 'timezone_id',
      key: 'timezone_id',
    },
    {
      title: 'Message Template Namespace',
      dataIndex: 'message_template_namespace',
      key: 'message_template_namespace',
    },
  ];

  return (
    <div className="whatsapp-provider-home-page">
      <h1>Welcome to WhatsApp Tech Provider</h1>
      <Button onClick={launchWhatsAppSignup}
        style={{
          backgroundColor: '#1877f2',
          border: 0,
          borderRadius: '4px',
          color: '#fff',
          cursor: 'pointer',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontSize: '16px',
          fontWeight: 'bold',
          height: '40px',
          padding: '0 24px',
          marginBottom: '20px'
        }}>
        Login with Facebook
      </Button>
      <Table dataSource={wabas} columns={columns} rowKey="id" loading={loading} />
    </div>
  );
};

export default WhatsAppProviderHomePage;

