import React from 'react';
import { Tabs, Form, Input, Button, Upload, message, Switch, Select } from 'antd';
import { UploadOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './SettingsPage.css';

const { TabPane } = Tabs;
const { Option } = Select;

const SettingsPage = () => {
  const [profileForm] = Form.useForm();
  const [businessProfileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const [socialMediaForm] = Form.useForm();
  const [themeForm] = Form.useForm();

  const handleProfileSubmit = (values) => {
    console.log('Profile data:', values);
    message.success('Profile updated successfully!');
  };

  const handleBusinessProfileSubmit = (values) => {
    console.log('Business profile data:', values);
    message.success('Business profile updated successfully!');
  };

  const handlePasswordSubmit = (values) => {
    console.log('Password data:', values);
    message.success('Password updated successfully!');
  };

  const handlePaymentSubmit = (values) => {
    console.log('Payment data:', values);
    message.success('Payment settings updated successfully!');
  };

  const handleSocialMediaSubmit = (values) => {
    console.log('Social media data:', values);
    message.success('Social media links updated successfully!');
  };

  const handleThemeSubmit = (values) => {
    console.log('Theme data:', values);
    message.success('Theme settings updated successfully!');
  };

  const handleUpload = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="settings-page">
      <h1>Settings</h1>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Profile" key="1">
          <Form
            form={profileForm}
            layout="vertical"
            onFinish={handleProfileSubmit}
            initialValues={{ username: 'Owner', email: 'owner@example.com' }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Profile Picture"
              name="profilePicture"
            >
              <Upload customRequest={handleUpload} listType="picture">
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Profile
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Business Profile" key="2">
          <Form
            form={businessProfileForm}
            layout="vertical"
            onFinish={handleBusinessProfileSubmit}
            initialValues={{
              businessName: 'My Business',
              address: '123 Business St.',
              phone: '123-456-7890',
              website: 'https://mybusiness.com',
              details: 'Leading provider of quality products.'
            }}
          >
            <Form.Item
              label="Business Name"
              name="businessName"
              rules={[{ required: true, message: 'Please input your business name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Business Address"
              name="address"
              rules={[{ required: true, message: 'Please input your business address!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{ required: true, message: 'Please input your business phone number!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Website"
              name="website"
              rules={[{ type: 'url', message: 'Please input a valid URL!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Business Details"
              name="details"
            >
              <Input.TextArea rows={4} placeholder="Enter details about your business" />
            </Form.Item>
            <Form.Item
              label="Business Logo"
              name="businessLogo"
            >
              <Upload customRequest={handleUpload} listType="picture">
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Business Profile
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Password" key="3">
          <Form
            form={passwordForm}
            layout="vertical"
            onFinish={handlePasswordSubmit}
          >
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[{ required: true, message: 'Please input your current password!' }]}
            >
              <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: 'Please input your new password!' }]}
            >
              <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={['newPassword']}
              rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Payment" key="4">
          <Form
            form={paymentForm}
            layout="vertical"
            onFinish={handlePaymentSubmit}
            initialValues={{ paymentMethod: 'PayPal', paypalEmail: 'business@paypal.com' }}
          >
            <Form.Item
              label="Payment Method"
              name="paymentMethod"
              rules={[{ required: true, message: 'Please select a payment method!' }]}
            >
              <Select>
                <Option value="PayPal">PayPal</Option>
                <Option value="Stripe">Stripe</Option>
                <Option value="Bank Transfer">Bank Transfer</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="PayPal Email"
              name="paypalEmail"
              rules={[{ required: true, type: 'email', message: 'Please input a valid PayPal email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Payment Settings
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Social Media" key="5">
          <Form
            form={socialMediaForm}
            layout="vertical"
            onFinish={handleSocialMediaSubmit}
            initialValues={{ facebook: 'https://facebook.com/mybusiness', twitter: 'https://twitter.com/mybusiness' }}
          >
            <Form.Item
              label="Facebook"
              name="facebook"
              rules={[{ required: true, type: 'url', message: 'Please input a valid URL!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Twitter"
              name="twitter"
              rules={[{ required: true, type: 'url', message: 'Please input a valid URL!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Instagram"
              name="instagram"
              rules={[{ type: 'url', message: 'Please input a valid URL!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Social Media Links
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Theme" key="6">
          <Form
            form={themeForm}
            layout="vertical"
            onFinish={handleThemeSubmit}
            initialValues={{ theme: 'Light' }}
          >
            <Form.Item
              label="Theme"
              name="theme"
              rules={[{ required: true, message: 'Please select a theme!' }]}
            >
              <Select>
                <Option value="Light">Light</Option>
                <Option value="Dark">Dark</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Custom CSS"
              name="customCss"
            >
              <Input.TextArea rows={4} placeholder="Enter custom CSS" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Theme Settings
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Notifications" key="7">
          <Form layout="vertical">
            <Form.Item label="Email Notifications" name="emailNotifications" valuePropName="checked">
              <Switch defaultChecked />
            </Form.Item>
            <Form.Item label="SMS Notifications" name="smsNotifications" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item>
              <Button type="primary">
                Save Notification Settings
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SettingsPage;
