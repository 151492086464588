import React, { useState } from 'react';
import { Form, Input, Button, Divider, Card, Row, Col, Typography, Tabs, message } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { createCityData, updateCityData, getCityData } from '../../../services/api';

const { TextArea } = Input;
const { Title } = Typography;
const { TabPane } = Tabs;

const CityAdminPage = () => {
  const [cityId, setCityId] = useState(''); // State to hold city ID
  const [cityData, setCityData] = useState({
    city_id: '',
    name: '',
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
    description: '',
    history: '',
    culture: '',
    services: [],
    popularRoutes: [],
    faqs: []
  });

  const [jsonInput, setJsonInput] = useState('');
  const [form] = Form.useForm();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCityData({
      ...cityData,
      [name]: value
    });
  };

  const handleJsonChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleJsonSubmit = async () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      form.setFieldsValue(parsedData); // Populate form with parsed JSON data
      const result = await createCityData(parsedData);
      message.success('City data created successfully from JSON!');
    } catch (error) {
      message.error('Invalid JSON format or failed to create city data. Please check and try again.');
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (cityId) {
        await updateCityData(cityId, values);
        message.success('City data updated successfully');
      } else {
        await createCityData(values);
        message.success('City data created successfully');
      }
    } catch (error) {
      message.error('Failed to save city data');
    }
  };

  const fetchCityData = async () => {
    try {
      const data = await getCityData(cityId);
      setCityData(data);
      form.setFieldsValue(data); // Populate form with fetched city data
      setJsonInput(JSON.stringify(data, null, 2)); // Populate the JSON input with formatted city data
      message.success('City data fetched successfully');
    } catch (error) {
      message.error('Failed to fetch city data');
    }
  };

  const addService = () => {
    const newService = { name: '', description: '', baseFare: 0, perKmRate: 0 };
    setCityData({ ...cityData, services: [...cityData.services, newService] });
  };

  const addRoute = () => {
    const newRoute = { routeName: '', distance: '', estimatedTime: '', description: '', fare: 0 };
    setCityData({ ...cityData, popularRoutes: [...cityData.popularRoutes, newRoute] });
  };

  const addFAQ = () => {
    const newFAQ = { question: '', answer: '' };
    setCityData({ ...cityData, faqs: [...cityData.faqs, newFAQ] });
  };

  return (
    <div className="city-admin-page" style={{ padding: '20px', maxWidth: '1000px', margin: '0 auto' }}>
      <Card>
        <Title level={2}>City Data Management</Title>
        <Input
          placeholder="Enter City ID"
          value={cityId}
          onChange={(e) => setCityId(e.target.value)}
          style={{ marginBottom: 20 }}
        />
        <Button type="primary" onClick={fetchCityData}>Fetch City Data</Button>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Form Fields" key="1">
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="City ID" name="city_id" rules={[{ required: true, message: 'Please input the City ID!' }]}>
                    <Input name="city_id" value={cityData.city_id} onChange={handleChange} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="City Name" name="name" rules={[{ required: true, message: 'Please input the City Name!' }]}>
                    <Input name="name" value={cityData.name} onChange={handleChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Meta Title" name="meta_title" rules={[{ required: true, message: 'Please input the Meta Title!' }]}>
                    <Input name="meta_title" value={cityData.meta_title} onChange={handleChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Meta Description" name="meta_description" rules={[{ required: true, message: 'Please input the Meta Description!' }]}>
                    <TextArea rows={4} name="meta_description" value={cityData.meta_description} onChange={handleChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Meta Keywords (comma separated)" name="meta_keywords" rules={[{ required: true, message: 'Please input the Meta Keywords!' }]}>
                    <Input name="meta_keywords" value={cityData.meta_keywords} onChange={handleChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input the Description!' }]}>
                    <TextArea rows={4} name="description" value={cityData.description} onChange={handleChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="History" name="history" rules={[{ required: true, message: 'Please input the History!' }]}>
                    <TextArea rows={4} name="history" value={cityData.history} onChange={handleChange} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Culture" name="culture" rules={[{ required: true, message: 'Please input the Culture!' }]}>
                    <TextArea rows={4} name="culture" value={cityData.culture} onChange={handleChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <h3>Services</h3>
              {cityData.services.map((service, index) => (
                <Row gutter={16} key={index}>
                  <Col span={8}>
                    <Form.Item label="Service Name">
                      <Input
                        value={service.name}
                        onChange={(e) => {
                          const newServices = [...cityData.services];
                          newServices[index].name = e.target.value;
                          setCityData({ ...cityData, services: newServices });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Description">
                      <Input
                        value={service.description}
                        onChange={(e) => {
                          const newServices = [...cityData.services];
                          newServices[index].description = e.target.value;
                          setCityData({ ...cityData, services: newServices });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Base Fare">
                      <Input
                        type="number"
                        value={service.baseFare}
                        onChange={(e) => {
                          const newServices = [...cityData.services];
                          newServices[index].baseFare = parseInt(e.target.value, 10);
                          setCityData({ ...cityData, services: newServices });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Per Km Rate">
                      <Input
                        type="number"
                        value={service.perKmRate}
                        onChange={(e) => {
                          const newServices = [...cityData.services];
                          newServices[index].perKmRate = parseInt(e.target.value, 10);
                          setCityData({ ...cityData, services: newServices });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Button type="dashed" onClick={addService} style={{ width: '100%' }}>
                <PlusOutlined /> Add Service
              </Button>

              <Divider />

              <h3>Popular Routes</h3>
              {cityData.popularRoutes.map((route, index) => (
                <Row gutter={16} key={index}>
                  <Col span={6}>
                    <Form.Item label="Route Name">
                      <Input
                        value={route.routeName}
                        onChange={(e) => {
                          const newRoutes = [...cityData.popularRoutes];
                          newRoutes[index].routeName = e.target.value;
                          setCityData({ ...cityData, popularRoutes: newRoutes });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Distance">
                      <Input
                        value={route.distance}
                        onChange={(e) => {
                          const newRoutes = [...cityData.popularRoutes];
                          newRoutes[index].distance = e.target.value;
                          setCityData({ ...cityData, popularRoutes: newRoutes });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Estimated Time">
                      <Input
                        value={route.estimatedTime}
                        onChange={(e) => {
                          const newRoutes = [...cityData.popularRoutes];
                          newRoutes[index].estimatedTime = e.target.value;
                          setCityData({ ...cityData, popularRoutes: newRoutes });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Description">
                      <Input
                        value={route.description}
                        onChange={(e) => {
                          const newRoutes = [...cityData.popularRoutes];
                          newRoutes[index].description = e.target.value;
                          setCityData({ ...cityData, popularRoutes: newRoutes });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Fare">
                      <Input
                        type="number"
                        value={route.fare}
                        onChange={(e) => {
                          const newRoutes = [...cityData.popularRoutes];
                          newRoutes[index].fare = parseInt(e.target.value, 10);
                          setCityData({ ...cityData, popularRoutes: newRoutes });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Button type="dashed" onClick={addRoute} style={{ width: '100%' }}>
                <PlusOutlined /> Add Route
              </Button>

              <Divider />

              <h3>FAQs</h3>
              {cityData.faqs.map((faq, index) => (
                <Row gutter={16} key={index}>
                  <Col span={12}>
                    <Form.Item label="Question">
                      <Input
                        value={faq.question}
                        onChange={(e) => {
                          const newFAQs = [...cityData.faqs];
                          newFAQs[index].question = e.target.value;
                          setCityData({ ...cityData, faqs: newFAQs });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Answer">
                      <Input
                        value={faq.answer}
                        onChange={(e) => {
                          const newFAQs = [...cityData.faqs];
                          newFAQs[index].answer = e.target.value;
                          setCityData({ ...cityData, faqs: newFAQs });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Button type="dashed" onClick={addFAQ} style={{ width: '100%' }}>
                <PlusOutlined /> Add FAQ
              </Button>

              <Divider />

              <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
                {cityId ? 'Update City Data' : 'Save City Data'}
              </Button>
            </Form>
          </TabPane>

          <TabPane tab="Paste JSON" key="2">
            <TextArea rows={10} placeholder="Paste your JSON here..." value={jsonInput} onChange={handleJsonChange} />
            <Button type="primary" onClick={handleJsonSubmit} style={{ marginTop: '10px' }} icon={<UploadOutlined />}>
              Load from JSON
            </Button>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default CityAdminPage;
