import React, { useState } from 'react';
import { Layout, Menu, Avatar, Dropdown, Breadcrumb, Card, Typography } from 'antd';
import {
  MessageOutlined,
  AppstoreAddOutlined,
  SendOutlined,
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
  BellOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import WhatsAppDashboardHome from './WhatsAppDashboardHome';
import MessagingPage from './MessagingPage';
import TemplateManagementPage from './TemplateManagementPage';
import CreateCampaignPage from './CreateCampaignPage';
import NotificationSettings from '../../Customer/NotificationSettings'; // Newly added
import CampaignDashboard from './CampaignDashboard';
import './Dashboard.css';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;

const UserMenu = ({ onLogout }) => (
  <Menu>
    <Menu.Item key="1" icon={<SettingOutlined />}>
      Settings
    </Menu.Item>
    <Menu.Item key="2" icon={<LogoutOutlined />} onClick={onLogout}>
      Logout
    </Menu.Item>
  </Menu>
);

const WhatsAppDashboard = () => {
  const [selectedMenu, setSelectedMenu] = useState('dashboard');

  const renderContent = () => {
    switch (selectedMenu) {
      case 'dashboard':
        return <WhatsAppDashboardHome />;
      case 'messages':
        return <MessagingPage />;
      case 'templates':
        return <TemplateManagementPage />;
      case 'campaigns':
        return <CampaignDashboard />;
      case 'notifications':
        return <NotificationSettings />;
      default:
        return <WhatsAppDashboardHome />;
    }
  };

  const handleLogout = () => {
    // Implement your logout logic here
    console.log('User logged out');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible breakpoint="lg" collapsedWidth="80" className="site-layout-background">
        {/* <div className="logo" style={{ height: '32px', margin: '16px', backgroundColor: '#fff', borderRadius: '4px' }} /> */}
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['dashboard']} onClick={e => setSelectedMenu(e.key)}>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="messages" icon={<MessageOutlined />}>
            Messaging
          </Menu.Item>
          <Menu.Item key="templates" icon={<AppstoreAddOutlined />}>
            Templates
          </Menu.Item>
          <Menu.Item key="campaigns" icon={<SendOutlined />}>
            Campaigns
          </Menu.Item>
          <Menu.Item key="notifications" icon={<BellOutlined />}>
            Notifications
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: '0 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title level={3} style={{ color: '#fff', margin: 0 }}>WhatsApp Dashboard</Title>
          <Dropdown overlay={<UserMenu onLogout={handleLogout} />} trigger={['click']}>
            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
          </Dropdown>
        </Header>
        <Content style={{ margin: '16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>{selectedMenu.charAt(0).toUpperCase() + selectedMenu.slice(1)}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {renderContent()}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default WhatsAppDashboard;
