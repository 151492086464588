import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import {
  HomeOutlined,
  DollarOutlined,
  PlayCircleOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import './Navbar.css';

const Navbar = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [current, setCurrent] = useState('home');
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.substring(1); // Remove the leading '/'
    setCurrent(pathname || 'home');
  }, [location]);

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const handleClick = (key) => {
    setCurrent(key);
    setIsDrawerVisible(false); // Close drawer on item click
  };

  return (
    <div className="navbar">
      <div className="navbar-content">
        <Link to="/" className="navbar-title">TaxiPro</Link>
        <div className="navbar-links">
          <Link
            to="/"
            className={`navbar-link ${current === 'home' ? 'active' : ''}`}
            onClick={() => handleClick('home')}
          >
            <HomeOutlined /> Home
          </Link>
          <Link
            to="/pricing"
            className={`navbar-link ${current === 'pricing' ? 'active' : ''}`}
            onClick={() => handleClick('pricing')}
          >
            <DollarOutlined /> Pricing
          </Link>
          <Link
            to="/demo"
            className={`navbar-link ${current === 'demo' ? 'active' : ''}`}
            onClick={() => handleClick('demo')}
          >
            <PlayCircleOutlined /> Demo
          </Link>
          <Link
            to="/contact"
            className={`navbar-link ${current === 'contact' ? 'active' : ''}`}
            onClick={() => handleClick('contact')}
          >
            <PhoneOutlined /> Contact
          </Link>
          <Link
            to="/about"
            className={`navbar-link ${current === 'about' ? 'active' : ''}`}
            onClick={() => handleClick('about')}
          >
            <InfoCircleOutlined /> About Us
          </Link>
        </div>
        <Button className="navbar-menu-button" onClick={toggleDrawer}>
          <MenuOutlined />
        </Button>
      </div>

      <Drawer
        title="Menu"
        placement="right"
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        bodyStyle={{ padding: 0 }}
      >
        <div className="drawer-menu">
          <Link
            to="/"
            className={`drawer-link ${current === 'home' ? 'active' : ''}`}
            onClick={() => handleClick('home')}
          >
            <HomeOutlined /> Home
          </Link>
          <Link
            to="/pricing"
            className={`drawer-link ${current === 'pricing' ? 'active' : ''}`}
            onClick={() => handleClick('pricing')}
          >
            <DollarOutlined /> Pricing
          </Link>
          <Link
            to="/demo"
            className={`drawer-link ${current === 'demo' ? 'active' : ''}`}
            onClick={() => handleClick('demo')}
          >
            <PlayCircleOutlined /> Demo
          </Link>
          <Link
            to="/contact"
            className={`drawer-link ${current === 'contact' ? 'active' : ''}`}
            onClick={() => handleClick('contact')}
          >
            <PhoneOutlined /> Contact
          </Link>
          <Link
            to="/about"
            className={`drawer-link ${current === 'about' ? 'active' : ''}`}
            onClick={() => handleClick('about')}
          >
            <InfoCircleOutlined /> About Us
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

export default Navbar;
