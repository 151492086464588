import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://ct-admin-server-1340377667.ap-south-1.elb.amazonaws.com',
  // baseURL: 'https://api.citytaxis.in'
  baseURL: 'http://localhost:3001'
  
});

// Customers

export const loginUser = async (credentials) => {
  const response = await api.post('/login', credentials);
  return response.data;
};

export const registerUser = async (data) => {
  const response = await api.post('/register', data);
  return response.data;
};

export const bookRide = async (bookingDetails) => {
  try {
    const response = await api.post('/api/bookings/book', bookingDetails);
    return response.data;
  } catch (error) {
    console.error('Error booking ride:', error.response ? error.response.data : error.message);
    throw new Error('Failed to book ride');
  }
};

export const getSuggestedLocations = async (query) => {
  try {
    const response = await api.get(`/api/locations/suggestions?q=${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
    throw new Error('Failed to fetch suggestions');
  }
};

export const getAllBookings = async () => {
  try {
    const response = await api.get('/api/bookings');
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch bookings');
  }
};

export const createOrder = async (createOrderRequest) => {
  try {
    console.log("Creating order with amount: ", createOrderRequest)
    const response = await api.post('/api/razorpay/create-order', createOrderRequest);
    console.log(response)
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
  }
};


// Drivers

export const getAllDrivers = async () => {
  const response = await api.get('/api/drivers/all');
  return response.data;
};

export const addDriver = async (driver) => {
  const response = await api.post('/api/drivers/add', driver);
  return response.data;
};

export const updateDriver = async (id, driver) => {
  const response = await api.put(`/api/drivers/update/${id}`, driver);
  return response.data;
};

export const deleteDriver = async (id) => {
  const response = await api.delete(`/api/drivers/delete/${id}`);
  return response.data;
};

// Vehicles

export const getAllVehicles = async () => {
  const response = await api.get('/api/vehicles/all');
  return response.data;
};

export const addVehicle = async (driver) => {
  const response = await api.post('/api/vehicles/add', driver);
  return response.data;
};

export const updateVehicle = async (id, driver) => {
  const response = await api.put(`/api/vehicles/update/${id}`, driver);
  return response.data;
};

export const deleteVehicle = async (id) => {
  const response = await api.delete(`/api/vehicles/delete/${id}`);
  return response.data;
};


// Whatsapp

export const fetchTemplates = async () => {
  const response = await api.get('/api/whatsapp/templates');
  return response.data;
}

export const createTemplate = async () => {
  const response = await api.get('/api/whatsapp/templates');
  return response.data;
}




export const sendMessage = async (sendMessageRequest) => {
  const response = await api.post('/api/whatsapp/send', sendMessageRequest)
  return response.data;

}

export const sendTextMessage = async (sendTextMessageRequest) => {
  const response = await api.post('/api/whatsapp/sendTextMessage', sendTextMessageRequest)
  return response.data;
}

export const sendWhatsAppMessage = async (sendWhatsAppMessageRequest) => {
  const response = await api.post('/api/whatsapp/sendWhatsAppMessage', sendWhatsAppMessageRequest)
  return response.data;
}

// Add this function to your services/api.js file

export const sendTemplateToAllUsers = async (template) => {
  try {
    const response = await api.post('/api/whatsapp/sendTemplateToAllUsers', { template });
    console.log(response)
    return response.data;
  } catch (error) {
    console.error('Failed to send template to all users:', error.response ? error.response.data : error.message);
    throw new Error('Failed to send template to all users');
  }
};




export const getBillById = async (id) => {
  const response = await api.get(`/api/bookings/${id}`, id)
  return response.data;

}

// Update booking details
export const updateBookingById = async (id, updateData) => {
  try {
    const response = await api.put(`/api/bookings/${id}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating booking info:', error);
    throw new Error('Failed to update booking info');
  }
};


// Update booking details
export const updateBookingInfo = async (id, updateData) => {
  try {
    const response = await api.put(`/api/bookings/${id}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating booking info:', error);
    throw new Error('Failed to update booking info');
  }
};

// Fetch city data by city ID
export const getCityData = async (city_id) => {
  try {
    const response = await api.get(`/api/pageData/city/${city_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching city data:', error);
    throw new Error('Failed to fetch city data');
  }
};

// Create new city data
export const createCityData = async (cityData) => {
  try {
    const response = await api.post('/api/pageData/city', cityData);
    return response.data;
  } catch (error) {
    console.error('Error creating city data:', error);
    throw new Error('Failed to create city data');
  }
};

// Update city data by city ID
export const updateCityData = async (city_id, updateData) => {
  try {
    const response = await api.put(`/api/pageData/city/${city_id}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Error updating city data:', error);
    throw new Error('Failed to update city data');
  }
};

// Delete city data by city ID
export const deleteCityData = async (city_id) => {
  try {
    const response = await api.delete(`/api/pageData/city/${city_id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting city data:', error);
    throw new Error('Failed to delete city data');
  }
};




// const handleSendMessage = async () => {
//   const manualContactsList = manualContacts.split(',').map(number => number.trim()).filter(Boolean);
//   const allContacts = [...selectedContacts, ...manualContactsList];
  
//   if (allContacts.length === 0) {
//     message.error('Please select or enter at least one contact.');
//     return;
//   }

//   try {
//     await fetch('/api/whatsapp/send', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ contacts: allContacts, templateName: selectedTemplate }),
//     });
//     message.success('Messages sent successfully!');
//     setSelectedContacts([]);
//     setManualContacts('');
//     setSelectedTemplate('');
//   } catch (err) {
//     message.error('Failed to send messages');
//   }
// };



export default api;
