import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import BookingForm from './BookingForm';
import CustomerReviews from './CustomerReviews';
import Features from './Features';

import './HeaderLayout.css';

const { Title, Paragraph } = Typography;

const HeaderLayout = ({ onBooking }) => {
  return (
    <Layout className="header-layout">
      <div className="header-content">
        <Row gutter={[32, 32]} align="middle" justify="center">
          <Col xs={24} md={10}>
        <BookingForm onFinish={onBooking} />
          </Col>
          <Col xs={24} md={10} className="product-info">
            <Title level={2} className="header-title">Welcome to CityTaxis</Title>
            <Paragraph className="header-description">
              Book your ride easily with our reliable and efficient service.
            </Paragraph>
            {/* <CustomerReviews /> */}
            {/* <Section /> */}
          </Col>
        </Row>
      </div>
      {/* <Features /> */}
    </Layout>
  );
};

export default HeaderLayout;
