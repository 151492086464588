import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const CustomizationPanel = ({ open, onClose, onSave, customization }) => {
  const [localCustomization, setLocalCustomization] = useState(customization);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalCustomization((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(localCustomization);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Customization
        <SettingsIcon style={{ float: 'right' }} />
      </DialogTitle>
      <DialogContent>
        <TextField
          name="companyName"
          label="Company Name"
          value={localCustomization.companyName}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          name="companyLogo"
          label="Company Logo URL"
          value={localCustomization.companyLogo}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          name="companyAddress"
          label="Company Address"
          value={localCustomization.companyAddress}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          name="companyPhone"
          label="Company Phone"
          value={localCustomization.companyPhone}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
        <TextField
          name="footerMessage"
          label="Footer Message"
          value={localCustomization.footerMessage}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomizationPanel;
