import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, message, Select, Card, Row, Col, Checkbox, Typography, List, Divider, Steps, Alert } from 'antd';
import { InboxOutlined, PlusOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { getAllBookings } from '../../../services/api'; // Import the API function
import './CreateCampaignPage.css';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;
const { Step } = Steps;

// Predefined templates
const predefinedTemplates = [
  {
    id: 1,
    name: 'Promotion Alert',
    content: 'Hello {name}, check out our latest promotions on {product}. Don’t miss out!',
  },
  {
    id: 2,
    name: 'Appointment Reminder',
    content: 'Hi {name}, this is a reminder for your appointment on {date}. Please confirm your availability.',
  },
  {
    id: 3,
    name: 'Follow-Up',
    content: 'Hi {name}, just checking in to see if you have any questions or need assistance.',
  },
  // Add more predefined templates as needed
];

const CreateCampaignPage = () => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [campaignType, setCampaignType] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [manualContacts, setManualContacts] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const handleTemplateChange = (templateId) => {
    const template = predefinedTemplates.find((t) => t.id === templateId);
    setSelectedTemplate(template);
    if (template) {
      form.setFieldsValue({ messageText: template.content });
    }
  };

  const addTemplateToSequence = () => {
    if (selectedTemplate) {
      setSelectedTemplates([...selectedTemplates, selectedTemplate]);
      setSelectedTemplate(null);
    }
  };

  const removeTemplateFromSequence = (index) => {
    const newSequence = selectedTemplates.filter((_, i) => i !== index);
    setSelectedTemplates(newSequence);
  };

  const moveTemplateUp = (index) => {
    if (index > 0) {
      const newSequence = [...selectedTemplates];
      [newSequence[index - 1], newSequence[index]] = [newSequence[index], newSequence[index - 1]];
      setSelectedTemplates(newSequence);
    }
  };

  const moveTemplateDown = (index) => {
    if (index < selectedTemplates.length - 1) {
      const newSequence = [...selectedTemplates];
      [newSequence[index + 1], newSequence[index]] = [newSequence[index], newSequence[index + 1]];
      setSelectedTemplates(newSequence);
    }
  };

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const fetchedBookings = await getAllBookings();
        setBookings(fetchedBookings);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const handleSelectAllContacts = (value) => {
    if (value.includes('selectAll')) {
      setSelectAll(!selectAll);
      form.setFieldsValue({
        contactList: selectAll ? [] : bookings.map((booking) => booking.phoneNumber),
      });
    }
  };

  const steps = [
    {
      title: 'Campaign Type',
      content: (
        <Card>
          <Form.Item
            name="campaignType"
            label="Select Campaign Type"
            rules={[{ required: true, message: 'Please select a campaign type' }]}
          >
            <Select onChange={(value) => setCampaignType(value)} placeholder="Select a campaign type">
              <Option value="event">Event Based</Option>
              <Option value="api">API Based</Option>
              <Option value="manual">Manual (Over a Period)</Option>
            </Select>
          </Form.Item>
        </Card>
      ),
    },
    {
      title: 'Campaign Details',
      content: (
        <Card>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Campaign Name"
                rules={[{ required: true, message: 'Please enter the campaign name' }]}
              >
                <Input placeholder="Enter campaign name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="objective"
                label="Campaign Objective"
                rules={[{ required: true, message: 'Please enter the campaign objective' }]}
              >
                <TextArea rows={3} placeholder="Describe the campaign objective" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="goal"
                label="Campaign Goal"
                rules={[{ required: true, message: 'Please define a goal for the campaign' }]}
              >
                <Input placeholder="e.g., Increase sales by 10%, Improve engagement" />
              </Form.Item>
            </Col>
            <Col span={12}>
              {campaignType === 'manual' && (
                <Form.Item
                  name="schedule"
                  label="Campaign Schedule"
                  rules={[{ required: true, message: 'Please select the schedule' }]}
                >
                  <RangePicker showTime style={{ width: '100%' }} />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      title: 'Contacts',
      content: (
        <Card>
          <Form.Item
            name="contactList"
            label="Contact List"
            rules={[{ required: true, message: 'Please select at least one contact' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select contacts from bookings"
              loading={loading}
              disabled={loading}
              optionLabelProp="label"
              onChange={handleSelectAllContacts}
              style={{ width: '100%' }}
            >
              <Option value="selectAll">Select All</Option>
              {bookings.map((booking) => (
                <Option key={booking.id} value={booking.phoneNumber} label={`${booking.customerName} - ${booking.phoneNumber}`}>
                  {`${booking.customerName} (${booking.phoneNumber})`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Manual Contacts"
            extra="Enter phone numbers separated by commas."
          >
            <Input
              value={manualContacts}
              onChange={(e) => setManualContacts(e.target.value)}
              placeholder="e.g., +1234567890, +0987654321"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Card>
      ),
    },
    {
      title: 'Templates',
      content: (
        <Card>
          <Alert
            message="Template Sequence"
            description="Select a series of templates to send during the campaign. You can rearrange the order as needed."
            type="info"
            showIcon
            style={{ marginBottom: '16px' }}
          />
          <Form.Item
            name="template"
            label="Select a Template"
          >
            <Select onChange={handleTemplateChange} placeholder="Select a template to add" style={{ width: '100%' }}>
              <Option value={null}>None</Option>
              {predefinedTemplates.map((template) => (
                <Option key={template.id} value={template.id}>
                  {template.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={addTemplateToSequence}
            disabled={!selectedTemplate}
            block
          >
            Add Template to Sequence
          </Button>
          <Divider />
          <List
            header={<div>Selected Templates Sequence</div>}
            bordered
            dataSource={selectedTemplates}
            renderItem={(template, index) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    icon={<ArrowUpOutlined />}
                    onClick={() => moveTemplateUp(index)}
                    disabled={index === 0}
                  />,
                  <Button
                    type="link"
                    icon={<ArrowDownOutlined />}
                    onClick={() => moveTemplateDown(index)}
                    disabled={index === selectedTemplates.length - 1}
                  />,
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => removeTemplateFromSequence(index)}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={<Typography.Text>{template.name}</Typography.Text>}
                  description={template.content}
                />
              </List.Item>
            )}
          />
        </Card>
      ),
    },
    {
      title: 'Review & Submit',
      content: (
        <Card title="Compliance and Opt-Out" bordered={false}>
          <Form.Item
            name="compliance"
            valuePropName="checked"
            rules={[{ required: true, message: 'Please ensure compliance' }]}
          >
            <Checkbox>
              I agree to comply with WhatsApp's terms and policies and provide an opt-out option.
            </Checkbox>
          </Form.Item>
        </Card>
      ),
    },
  ];

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const allContacts = [
        ...values.contactList,
        ...manualContacts.split(',').map((contact) => contact.trim()).filter(Boolean),
      ];
      console.log('Campaign Data:', { ...values, contacts: allContacts, templates: selectedTemplates });
      message.success('Campaign created successfully!');
      // Add logic to save the campaign data to the backend
    });
  };

  return (
    <div className="create-campaign-page">
      <Title level={2} style={{ marginBottom: '20px' }}>Create WhatsApp Campaign</Title>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Form form={form} layout="vertical">
        <Steps current={currentStep} style={{ marginBottom: '20px' }}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content" style={{ marginBottom: '20px' }}>{steps[currentStep].content}</div>
        <div className="steps-action" style={{ textAlign: 'right' }}>
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={handlePrev}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={handleNext}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CreateCampaignPage;
