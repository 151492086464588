import React from 'react';
import './Footer.css';
import { InstagramOutlined, TwitterOutlined, FacebookOutlined } from '@ant-design/icons';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-block">
          <h4>About TaxiPro</h4>
          <p>TaxiPro provides comprehensive solutions for managing taxi services, enhancing operational efficiency.</p>
        </div>
        <div className="footer-block">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/pricing">Pricing</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/support">Support</a></li>
          </ul>
        </div>
        <div className="footer-block">
          <h4>Follow Us</h4>
          <div className="social-links">
            <a href="https://twitter.com/taxipro" aria-label="Twitter"><TwitterOutlined /></a>
            <a href="https://facebook.com/taxipro" aria-label="Facebook"><FacebookOutlined /></a>
            <a href="https://instagram.com/taxipro" aria-label="Instagram"><InstagramOutlined /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 TaxiPro. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
