import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Layout,
  Button,
  Table,
  Input,
  Row,
  Col,
  Divider,
  Form,
  message,
  Space,
  Card,
} from 'antd';
import { IconButton } from '@mui/material';
import { getBillById, updateBookingInfo } from '../../../services/api';
import CustomizationPanel from '../../Customer/CustomizationPanel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  SettingOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  PlusOutlined,
  
} from '@ant-design/icons';
import './BillPage.css';

const { Header, Content } = Layout;

const BillPage = () => {
  const { id } = useParams();
  const [bill, setBill] = useState(null);
  const [customization, setCustomization] = useState({
    companyName: 'CityTaxis',
    companyLogo:
      'https://static.wixstatic.com/media/baf5fd_a33b11dfd9784d6a8a8c5a9a89f4ccc3~mv2.png',
    footerMessage: '',
    companyAddress: '123 City Road, CityTown',
    companyPhone: '+1-234-567-890',
    primaryColor: '#FFD700', // Yellow
    secondaryColor: '#000000', // Black
    textColor: '#FFFFFF', // White text for contrast
  });
  const [isCustomizationOpen, setIsCustomizationOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({
    pickupLocation: '',
    dropLocation: '',
    description: '',
    price: 0,
  });
  const [editIndex, setEditIndex] = useState(null);
  const [isEditingMainRow, setIsEditingMainRow] = useState(false);
  const [isEditingBillTo, setIsEditingBillTo] = useState(false);

  useEffect(() => {
    const fetchBill = async () => {
      try {
        console.log('Fetching bill with id: ', id);
        const response = await getBillById(id);
        if (response.success) {
          setBill({
            ...response.item,
            customerName: response.item.customerName || 'Default Name',
            customerEmail: response.item.customerEmail || 'default@example.com',
            customerPhone: response.item.phoneNumber || '+1-000-000-0000',
          });
          setItems(response.item.items || []); // assuming `items` is a field in the bill object
          if (response.item.price) {
            setBill((prevState) => ({ ...prevState, price: response.item.price }));
          }
        }
      } catch (error) {
        console.error('Error fetching bill:', error);
      }
    };

    fetchBill();
  }, [id]);

  const handleCustomizationSave = (customSettings) => {
    setCustomization(customSettings);
  };

  const handleAddItem = () => {
    setItems([...items, { ...newItem }]);
    setNewItem({ pickupLocation: '', dropLocation: '', description: '', price: 0 });
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleNewItemChange = (key, value) => {
    setNewItem({ ...newItem, [key]: value });
  };

  const handleEditItemChange = (index, key, value) => {
    const updatedItems = [...items];
    updatedItems[index][key] = value;
    setItems(updatedItems);
  };

  const handleEditBillTo = () => {
    setIsEditingBillTo(true);
  };

  const handleSaveBillTo = async () => {
    try {
      // Update bill in the database
      const updatedDetails = {
        customerName: bill.customerName,
        customerEmail: bill.customerEmail,
        phoneNumber: bill.phoneNumber,
      };

      const response = await updateBookingInfo(id, updatedDetails);
      if (response.success) {
        message.success('Bill updated successfully');
        setIsEditingBillTo(false);
      } else {
        message.error('Failed to update bill:', response.message);
      }
    } catch (error) {
      console.error('Error updating bill:', error);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const handleSave = () => {
    setEditIndex(null);
  };

  const handleEditMainRow = () => {
    setIsEditingMainRow(true);
  };

  const handleSaveMainRow = () => {
    setIsEditingMainRow(false);
  };

  const generatePDF = () => {
    const input = document.getElementById('bill-details');
    const downloadButton = document.getElementById('download-button');
    const actionButtons = document.querySelectorAll('.action-button');
    actionButtons.forEach((button) => (button.style.display = 'none')); // Hide action buttons

    // Ensure images are fully loaded before capturing the canvas
    const images = input.getElementsByTagName('img');
    const promises = [];

    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      if (!img.complete) {
        promises.push(
          new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve;
          })
        );
      }
    }

    Promise.all(promises).then(() => {
      html2canvas(input, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.text(customization.companyName || 'CityTaxis Bill', 10, 10);
        pdf.addImage(imgData, 'PNG', 0, 20, 210, (canvas.height * 210) / canvas.width);
        pdf.save('bill.pdf');
        downloadButton.style.display = 'block'; // Show the button again
        actionButtons.forEach((button) => (button.style.display = '')); // Restore action buttons
      });
    });
  };

  const calculateTotal = () => {
    const totalMainRow = parseFloat(bill?.price || 0);
    const totalItems = items.reduce((total, item) => total + parseFloat(item.price), 0);
    return (totalMainRow + totalItems);
  };

  const columns = [
    {
      title: 'Pickup Location',
      dataIndex: 'pickupLocation',
      render: (text, record, index) => {
        return editIndex === index ? (
          <Input
            value={text}
            onChange={(e) => handleEditItemChange(index, 'pickupLocation', e.target.value)}
            style={{ backgroundColor: '#fff', borderRadius: '4px' }}
          />
        ) : (
          text
        );
      },
    },
    {
      title: 'Drop Location',
      dataIndex: 'dropLocation',
      render: (text, record, index) => {
        return editIndex === index ? (
          <Input
            value={text}
            onChange={(e) => handleEditItemChange(index, 'dropLocation', e.target.value)}
            style={{ backgroundColor: '#fff', borderRadius: '4px' }}
          />
        ) : (
          text
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record, index) => {
        return editIndex === index ? (
          <Input
            value={text}
            onChange={(e) => handleEditItemChange(index, 'description', e.target.value)}
            style={{ backgroundColor: '#fff', borderRadius: '4px' }}
          />
        ) : (
          text
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (text, record, index) => {
        return editIndex === index ? (
          <Input
            type="number"
            value={text}
            onChange={(e) => handleEditItemChange(index, 'price', parseFloat(e.target.value))}
            style={{ backgroundColor: '#fff', borderRadius: '4px' }}
          />
        ) : (
          text
        );
      },
    },
    {
      title: 'Actions',
      render: (_, record, index) => (
        <Space size="middle">
          <IconButton
            onClick={editIndex === index ? handleSave : () => handleEdit(index)}
            style={{ color: customization.secondaryColor }}
          >
            {editIndex === index ? <CheckOutlined /> : <EditOutlined />}
          </IconButton>
          <IconButton onClick={() => handleRemoveItem(index)} style={{ color: 'red' }}>
            <DeleteOutlined />
          </IconButton>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          backgroundColor: customization.primaryColor,
          color: customization.textColor,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 24px',
        }}
      >
        <Typography.Title level={4} style={{ color: customization.textColor, margin: 0 }}>
          Bill Details
        </Typography.Title>
        <IconButton onClick={() => setIsCustomizationOpen(true)} style={{ color: customization.textColor }}>
          <SettingOutlined />
        </IconButton>
      </Header>
      <Content style={{ padding: '24px', maxWidth: '1200px', margin: 'auto' }}>
        <CustomizationPanel
          open={isCustomizationOpen}
          onClose={() => setIsCustomizationOpen(false)}
          onSave={handleCustomizationSave}
          customization={customization}
        />
        {bill ? (
          <Card style={{ borderRadius: '8px', padding: '24px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Row gutter={24} align="middle">
              <Col span={4}>
                <img
                  src={customization.companyLogo}
                  alt="Company Logo"
                  style={{ maxWidth: '100%', borderRadius: '8px' }}
                />
              </Col>
              <Col span={20}>
                <Typography.Title level={5} style={{ textAlign: 'right', marginBottom: 0 }}>
                  {customization.companyName}
                </Typography.Title>
                <Typography.Text style={{ textAlign: 'right', display: 'block' }}>
                  {customization.companyAddress}
                </Typography.Text>
                <Typography.Text style={{ textAlign: 'right', display: 'block' }}>
                  {customization.companyPhone}
                </Typography.Text>
              </Col>
            </Row>
            <Divider />
            <Typography.Title level={5} style={{ marginTop: '24px' }}>
              Bill To
            </Typography.Title>
            <Form layout="vertical">
              <Form.Item label="Customer Name">
                {isEditingBillTo ? (
                  <Input
                    value={bill.customerName || ''}
                    onChange={(e) => setBill({ ...bill, customerName: e.target.value })}
                    style={{ backgroundColor: '#fff', borderRadius: '4px' }}
                  />
                ) : (
                  <Typography.Text>{bill.customerName || 'Default Name'}</Typography.Text>
                )}
              </Form.Item>
              <Form.Item label="Customer Email">
                {isEditingBillTo ? (
                  <Input
                    value={bill.customerEmail || ''}
                    onChange={(e) => setBill({ ...bill, customerEmail: e.target.value })}
                    style={{ backgroundColor: '#fff', borderRadius: '4px' }}
                  />
                ) : (
                  <Typography.Text>{bill.customerEmail || 'default@example.com'}</Typography.Text>
                )}
              </Form.Item>
              <Form.Item label="Customer Phone">
                {isEditingBillTo ? (
                  <Input
                    value={bill.customerPhone || ''}
                    onChange={(e) => setBill({ ...bill, customerPhone: e.target.value })}
                    style={{ backgroundColor: '#fff', borderRadius: '4px' }}
                  />
                ) : (
                  <Typography.Text>{bill.customerPhone || '+1-000-000-0000'}</Typography.Text>
                )}
              </Form.Item>
              <IconButton
                onClick={isEditingBillTo ? handleSaveBillTo : handleEditBillTo}
                style={{ color: customization.secondaryColor }}
              >
                {isEditingBillTo ? <CheckOutlined /> : <EditOutlined />}
              </IconButton>
            </Form>
            <Divider />
            <Table
              dataSource={[bill, ...items]}
              columns={columns}
              pagination={false}
              rowKey={(record, index) => index}
              footer={() => (
                <Typography.Title level={5} style={{ textAlign: 'right', marginTop: '24px' }}>
                  Total: {calculateTotal()}
                </Typography.Title>
              )}
            />
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={handleAddItem}
              block
              style={{
                marginTop: '16px',
                backgroundColor: customization.primaryColor,
                color: customization.textColor,
              }}
            >
              Add Item
            </Button>
            {customization.footerMessage && (
              <div
                style={{
                  marginTop: '24px',
                  padding: '16px',
                  backgroundColor: '#f9f9f9',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography.Text>{customization.footerMessage}</Typography.Text>
              </div>
            )}
            <Button
              type="primary"
              id="download-button"
              onClick={generatePDF}
              style={{
                marginTop: '24px',
                width: '100%',
                backgroundColor: customization.secondaryColor,
                color: customization.textColor,
                borderRadius: '4px',
              }}
            >
              Download PDF
            </Button>
          </Card>
        ) : (
          <Typography.Text>Loading...</Typography.Text>
        )}
      </Content>
    </Layout>
  );
};

export default BillPage;
