import React from 'react';
import { Card, Col, Row, Typography, Button } from 'antd';
import { PlusOutlined, AppstoreOutlined } from '@ant-design/icons';
import './CreateTemplatePage.css'; // Assume custom styling is defined here

const { Title } = Typography;

const CreateTemplatePage = () => {
  return (
    <div className="create-template-page">
      <Title level={2} className="page-title">Create Template</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Card
            hoverable
            className="option-card"
            onClick={() => console.log('Create new template')}
          >
            <PlusOutlined className="icon-large" />
            <Title level={4}>Use a Blank Template</Title>
            <p>Create your template from scratch. Once you've finished creating your template, it must be submitted for review.</p>
            <Button type="primary" onClick={() => console.log('Navigate to create new template')}>
              Create new template
            </Button>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            hoverable
            className="option-card"
            onClick={() => console.log('Browse templates')}
          >
            <AppstoreOutlined className="icon-large" />
            <Title level={4}>Browse the WhatsApp template library</Title>
            <p>Get started faster with pre-written utility templates. Use a template as is and it will be available to send immediately, or customize the content to your needs.</p>
            <Button type="primary" onClick={() => console.log('Navigate to browse templates')}>
              Browse templates
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateTemplatePage;
