import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, message, Radio } from 'antd';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/authSlice';

const { Title } = Typography;

const Login = () => {
  const [loginType, setLoginType] = useState('email');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn, error } = useSelector(state => state.auth);


  useEffect(() => {
    const checkSession = () => {
      const loginTime = localStorage.getItem('loginTime');
      const token = localStorage.getItem('authToken');
  
      if (token && loginTime) {
        const elapsed = Date.now() - parseInt(loginTime, 10);
        const sessionDuration = 1800 * 1000; // 1 minute
  
        if (elapsed > sessionDuration) {
          message.warning('Session expired. Please log in again.');
          dispatch({ type: 'auth/logout' }); // Assume there's an action to clear auth state
          localStorage.removeItem('authToken');
          localStorage.removeItem('loginTime');
          navigate('/login');
        }
      }
    };
  
    // Run the check immediately and set a timer to check every minute
    checkSession();
    const interval = setInterval(checkSession, 60 * 1000);
  
    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch, navigate]);
  
useEffect(() => {
  const token = localStorage.getItem('authToken');
  if (token) {
    dispatch({ type: 'auth/loginSuccess', payload: token });
  }
}, [dispatch]);


  useEffect(() => {
    if (isLoggedIn) {
      message.success('Login successful!');
      navigate('/admin'); // Redirect to admin page
    }
  }, [isLoggedIn, navigate]);

  const onFinish = async (values) => {
    try {
      const result = await dispatch(loginUser(values));
      if (result.payload) { // Assuming your redux action returns the token in payload
        const token = result.payload.token; // Adjust based on your action's response
        localStorage.setItem('authToken', token);
        localStorage.setItem('loginTime', Date.now());
        message.success('Login successful!');
        navigate('/admin'); // Redirect to admin page
      } else {
        message.error('Failed to login. Please check your credentials.');
      }
    } catch (error) {
      console.error('Failed to login:', error);
      message.error('Failed to login. Please check your credentials.');
    }
  };
  

  const handleLoginTypeChange = (e) => {
    setLoginType(e.target.value);
  };

  return (
    <div className="login-container">
      <Form
        name="login"
        onFinish={onFinish}
        className="login-form"
        initialValues={{ remember: true }}
      >
        <Title level={2} className="login-title">Login to Your Account</Title>
        <Radio.Group onChange={handleLoginTypeChange} value={loginType} className="login-type-selector">
          <Radio.Button value="email"><MailOutlined /> Email</Radio.Button>
          <Radio.Button value="phone"><PhoneOutlined /> Phone</Radio.Button>
        </Radio.Group>
        <Form.Item
          name="username"
          rules={[{ required: true, message: `Please input your ${loginType}!` }]}
        >
          <Input prefix={<UserOutlined />} placeholder={loginType === 'email' ? 'Email' : 'Phone Number'} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block className="login-button">Login</Button>
        </Form.Item>
        <p className="register-link">
          Don't have an account? <Link to="/register">Register now</Link>
        </p>
      </Form>
    </div>
  );
};

export default Login;
