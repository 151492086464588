import React, { useState } from 'react';
import { Layout, Card, Switch, Typography, Tabs } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const initialNotificationSettings = {
  userNotifications: {
    paymentReceived: {
      email: true,
      sms: false,
      push: true,
      description: "Receive notifications when a payment is processed successfully."
    },
    profileUpdated: {
      email: true,
      sms: false,
      push: false,
      description: "Get alerted when your profile information is updated."
    },
  },
  customerNotifications: {
    bookingConfirmation: {
      email: true,
      sms: true,
      push: true,
      description: "Customers receive a confirmation when their booking is secured."
    },
    driverAssigned: {
      email: false,
      sms: true,
      push: true,
      description: "Notify customers when a driver is assigned to their booking."
    },
  }
};

const NotificationSettings = () => {
  const [settings, setSettings] = useState(initialNotificationSettings);

  const handleToggle = (category, event, channel) => {
    const newSettings = {
      ...settings,
      [category]: {
        ...settings[category],
        [event]: {
          ...settings[category][event],
          [channel]: !settings[category][event][channel]
        }
      }
    };
    setSettings(newSettings);
  };

  const renderNotificationSettings = (category) => (
    Object.entries(settings[category]).map(([event, { email, sms, push, description }]) => (
      <Card key={event} bordered={false} style={{ marginBottom: 16 }}>
        <Title level={5}>{event.split(/(?=[A-Z])/).join(" ").replace(/\b\w/g, l => l.toUpperCase())}</Title>
        <Paragraph>{description}</Paragraph>
        <div>
          Email: <Switch checked={email} onChange={() => handleToggle(category, event, 'email')} />
          SMS: <Switch checked={sms} onChange={() => handleToggle(category, event, 'sms')} />
          Push: <Switch checked={push} onChange={() => handleToggle(category, event, 'push')} />
        </div>
      </Card>
    ))
  );

  return (
    <Layout>
      <Header style={{ background: '#fff', padding: 0 }}>
        <Title level={2} style={{ padding: '16px' }}>Notification Settings</Title>
      </Header>
      <Content style={{ padding: '24px' }}>
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab={<span><NotificationOutlined />User Notifications</span>} key="1">
            {renderNotificationSettings('userNotifications')}
          </TabPane>
          <TabPane tab={<span><NotificationOutlined />Customer Notifications</span>} key="2">
            {renderNotificationSettings('customerNotifications')}
          </TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
};

export default NotificationSettings;
