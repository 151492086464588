import React from 'react';
import HeaderLayout from './HeaderLayout';
import ContentLayout from './ContentLayout';


const Home = () => {
  const handleBooking = async (values) => {
    try {
      // Perform the booking action
      console.log('Booking values:', values);
    } catch (error) {
      console.error('Failed to book ride', error);
    }
  };

  return (
    <>
      <HeaderLayout onBooking={handleBooking} />
      <ContentLayout />
    </>
  );
};

export default Home;
