import React, { useState, useEffect } from 'react';
import { Table, Spin, Alert, Button, Modal, Form, Input, Select, message, Popconfirm, Card, Row, Col, Space, Typography, Avatar } from 'antd';
import { getAllVehicles, addVehicle, updateVehicle, deleteVehicle } from '../../../services/api';
import { FileExcelOutlined, PlusOutlined, CarOutlined } from '@ant-design/icons';
import '../AdminCommon.css';

const { Option } = Select;
const { Title } = Typography;

const AdminVehiclesPage = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const fetchedVehicles = await getAllVehicles();
        setVehicles(fetchedVehicles);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAddVehicle = () => {
    setIsEditing(false);
    setSelectedVehicle(null);
    setModalVisible(true);
  };

  const handleEditVehicle = (vehicle) => {
    setIsEditing(true);
    setSelectedVehicle(vehicle);
    setModalVisible(true);
  };

  const handleDeleteVehicle = async (vehicleId) => {
    try {
      await deleteVehicle(vehicleId);
      const updatedVehicles = await getAllVehicles();
      setVehicles(updatedVehicles);
      message.success('Vehicle deleted successfully');
    } catch (err) {
      setError(err.message);
      message.error('Failed to delete vehicle');
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isEditing) {
        await updateVehicle(selectedVehicle._id, values);
        message.success('Vehicle updated successfully');
      } else {
        await addVehicle(values);
        message.success('Vehicle added successfully');
      }
      const updatedVehicles = await getAllVehicles();
      setVehicles(updatedVehicles);
      setModalVisible(false);
    } catch (err) {
      setError(err.message);
      message.error('Failed to save vehicle');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredVehicles = vehicles.filter((vehicle) =>
    vehicle.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vehicle.licensePlate.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vehicle.licensePlateColor.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const exportToCSV = () => {
    message.info('Exporting to CSV...');
    // Implement CSV export logic here
  };

  if (loading) return <Spin tip="Loading..." />;
  if (error) return <Alert message="Error" description={error} type="error" showIcon />;

  const columns = [
    {
      title: 'Vehicle',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Space>
          <Avatar icon={<CarOutlined />} />
          {type}
        </Space>
      ),
    },
    {
      title: 'License Plate',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
    },
    {
      title: 'Color',
      dataIndex: 'licensePlateColor',
      key: 'licensePlateColor',
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      key: 'driver',
      render: (driver) => driver ? driver.name : 'Not assigned',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status}
          onChange={(newStatus) => updateVehicle(record._id, { status: newStatus })}
          style={{ width: 120 }}
        >
          <Option value="available">Available</Option>
          <Option value="on_trip">On Trip</Option>
          <Option value="maintenance">Maintenance</Option>
        </Select>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditVehicle(record)} aria-label="Edit Vehicle">Edit</Button>
          <Popconfirm
            title="Are you sure you want to delete this vehicle?"
            onConfirm={() => handleDeleteVehicle(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger aria-label="Delete Vehicle">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const renderCardView = () => (
    <div className="card-container">
      {filteredVehicles.map((vehicle) => (
        <Card key={vehicle._id} className="vehicle-card">
          <Space>
            <Avatar icon={<CarOutlined />} size={64} />
            <div>
              <p><strong>{vehicle.type}</strong></p>
              <p>License: {vehicle.licensePlate}</p>
              <p>Color: {vehicle.licensePlateColor}</p>
            </div>
          </Space>
          <div>
            <p><strong>Driver:</strong> {vehicle.driver ? vehicle.driver.name : 'Not assigned'}</p>
            <Select
              value={vehicle.status}
              onChange={(newStatus) => updateVehicle(vehicle._id, { status: newStatus })}
              style={{ width: 120, marginBottom: 8 }}
            >
              <Option value="available">Available</Option>
              <Option value="on_trip">On Trip</Option>
              <Option value="maintenance">Maintenance</Option>
            </Select>
          </div>
          <Space size="middle" className="actions-column">
            <Button onClick={() => handleEditVehicle(vehicle)} aria-label="Edit Vehicle">Edit</Button>
            <Popconfirm
              title="Are you sure you want to delete this vehicle?"
              onConfirm={() => handleDeleteVehicle(vehicle._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger aria-label="Delete Vehicle">Delete</Button>
            </Popconfirm>
          </Space>
        </Card>
      ))}
    </div>
  );

  return (
    <div className="admin-vehicles-page">
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Title level={3}>Vehicle Management</Title>
        </Col>
        <Col>
          <Space>
            <Button type="primary" onClick={handleAddVehicle} icon={<PlusOutlined />}>
              Add Vehicle
            </Button>
            <Button icon={<FileExcelOutlined />} onClick={exportToCSV}>
              Export CSV
            </Button>
            <Input.Search
              placeholder="Search vehicles"
              value={searchTerm}
              onChange={handleSearch}
              onSearch={handleSearch}
              style={{ width: 300 }}
              allowClear
              enterButton
            />
          </Space>
        </Col>
      </Row>
      {isMobile ? renderCardView() : <Table dataSource={filteredVehicles} columns={columns} rowKey="_id" />}
      <Modal
        title={isEditing ? 'Edit Vehicle' : 'Add Vehicle'}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleSubmit} initialValues={selectedVehicle}>
          <Form.Item
            label="Vehicle Type"
            name="type"
            rules={[{ required: true, message: 'Please input the vehicle type!' }]}
          >
            <Select placeholder="Select a vehicle type">
              <Option value="Car">Car</Option>
              <Option value="Truck">Truck</Option>
              <Option value="Motorcycle">Motorcycle</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="License Plate"
            name="licensePlate"
            rules={[{ required: true, message: 'Please input the license plate!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="License Plate Color"
            name="licensePlateColor"
            rules={[{ required: true, message: 'Please select the license plate color!' }]}
          >
            <Select placeholder="Select a license plate color">
              <Option value="Yellow">Yellow</Option>
              <Option value="White">White</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Driver"
            name="driver"
            rules={[{ required: false }]}
          >
            <Select placeholder="Select a driver" allowClear>
              {/* Populate with drivers */}
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Please select the status!' }]}
          >
            <Select placeholder="Select status">
              <Option value="available">Available</Option>
              <Option value="on_trip">On Trip</Option>
              <Option value="maintenance">Maintenance</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isEditing ? 'Update Vehicle' : 'Add Vehicle'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminVehiclesPage;
