import React from 'react';
import { Button, Card, Typography, Row } from 'antd';
import {
  CheckCircleOutlined,
  UserOutlined,
  EditOutlined,
  MessageOutlined,
  DollarOutlined,
  BellOutlined,
} from '@ant-design/icons';
import './LandingPage.css';
import Navbar from '../components/Common/Navbar';
import Footer from '../components/Common/Footer';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const features = [
  { icon: <UserOutlined className="feature-icon" />, title: 'Manage Customers & Bookings', description: 'Centralized management for customers, bookings, drivers, and vehicles.' },
  { icon: <EditOutlined className="feature-icon" />, title: 'SEO & Content Generation', description: 'Automated SEO and content creation to boost your online presence.' },
  { icon: <MessageOutlined className="feature-icon" />, title: 'WhatsApp Business Messaging', description: 'Instant customer communication via WhatsApp.' },
  { icon: <DollarOutlined className="feature-icon" />, title: 'GST Bill Generation', description: 'Simplified, accurate GST billing to save time and reduce errors.' },
  { icon: <BellOutlined className="feature-icon" />, title: 'Notifications & Alerts', description: 'Alerts for abandoned carts, new bookings, and trip completions.' }
];

const LandingPage = () => {
  const navigate = useNavigate();
  const handleGetStartedClick = () => navigate('/login');
  const handleLearnMoreClick = () => window.open('https://ashwanijha04.github.io/Pitches/Taxipro/');

  return (
    <div className="landing-page">
      <Navbar />
      <header className="landing-header">
        <div className="header-content">
          <Title level={1} className="header-title">Revolutionize Your Taxi Business Operations with TaxiPro</Title>
          <Paragraph className="header-subtitle">Streamline your business with our all-in-one taxi management solution.</Paragraph>
          <div className="cta-buttons">
            <Button type="primary" size="large" className="cta-button" onClick={handleGetStartedClick}>Get Started</Button>
            <Button size="large" className="learn-more-button" onClick={handleLearnMoreClick}>Learn More</Button>
          </div>
        </div>
      </header>

      <div className="features-title">
          <Title level={2}>Key Features</Title>
      </div>

      <section className="features-section">
        <Row>

        {features.map((feature, index) => (
          <Card key={index} className="feature-card" bordered={false}>
            {feature.icon}
            <Title level={4} className="feature-title">{feature.title}</Title>
            <Paragraph className="feature-description">{feature.description}</Paragraph>
          </Card>
        ))}

        </Row>
      </section>
      <Footer />
    </div>
  );
};

export default LandingPage;
