import React from 'react';
import { Card, Button, Typography, Table } from 'antd';
import Navbar from '../components/Common/Navbar';
import Footer from '../components/Common/Footer';
import './PricingPage.css';

const { Title, Paragraph } = Typography;

const pricingData = [
  {
    key: '1',
    plan: 'Basic',
    price: '$29/month',
    features: ['Manage Customers', 'Bookings Management'],
  },
  {
    key: '2',
    plan: 'Standard',
    price: '$49/month',
    features: ['Manage Customers', 'Bookings Management', 'WhatsApp Messaging'],
  },
  {
    key: '3',
    plan: 'Premium',
    price: '$79/month',
    features: [
      'Manage Customers',
      'Bookings Management',
      'WhatsApp Messaging',
      'SEO & Content',
      'GST Bill Generation',
    ],
  },
];

const featureComparison = [
  {
    key: '1',
    feature: 'Manage Customers & Bookings',
    basic: true,
    standard: true,
    premium: true,
  },
  {
    key: '2',
    feature: 'WhatsApp Business Messaging',
    basic: false,
    standard: true,
    premium: true,
  },
  {
    key: '3',
    feature: 'SEO & Content Generation',
    basic: false,
    standard: false,
    premium: true,
  },
  {
    key: '4',
    feature: 'GST Bill Generation',
    basic: false,
    standard: false,
    premium: true,
  },
  {
    key: '5',
    feature: 'Notifications & Alerts',
    basic: false,
    standard: true,
    premium: true,
  },
];

const columns = [
  {
    title: 'Feature',
    dataIndex: 'feature',
    key: 'feature',
  },
  {
    title: 'Basic',
    dataIndex: 'basic',
    key: 'basic',
    render: (available) => (available ? '✓' : '✕'),
  },
  {
    title: 'Standard',
    dataIndex: 'standard',
    key: 'standard',
    render: (available) => (available ? '✓' : '✕'),
  },
  {
    title: 'Premium',
    dataIndex: 'premium',
    key: 'premium',
    render: (available) => (available ? '✓' : '✕'),
  },
];

const PricingCard = ({ plan, price, features }) => (
  <Card className="pricing-card" title={plan} bordered={false}>
    <div className="pricing-price">{price}</div>
    <ul className="pricing-features">
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <Button type="primary" size="large" className="pricing-button" aria-label={`Get started with the ${plan} plan`}>
      Get Started
    </Button>
  </Card>
);

const PricingPage = () => {
  return (
    <div className="pricing-page">
      <Navbar />

      <section className="pricing-header">
        <div className="header-content">
          <Title level={1} className="header-title">
            Choose Your Plan
          </Title>
          <Paragraph className="header-subtitle">
            Select the perfect plan for your business and unlock the full
            potential of TaxiPro.
          </Paragraph>
        </div>
      </section>

      <section className="pricing-section">
        <div className="pricing-cards">
          {pricingData.map((plan) => (
            <PricingCard key={plan.key} {...plan} />
          ))}
        </div>
      </section>

      <section className="comparison-section">
        <Title level={2} className="comparison-title">
          Feature Comparison
        </Title>
        <Table
          dataSource={featureComparison}
          columns={columns}
          pagination={false}
          bordered
          className="comparison-table"
        />
      </section>

      <Footer />
    </div>
  );
};

export default PricingPage;
