import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import './PaymentSuccess.css';

const { Title, Paragraph, Text } = Typography;

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { paymentId, orderId, signature } = location.state || {};

  return (
    <div className="payment-success-container">
      <Card className="payment-success-card">
        <Row justify="center">
          <Col>
            <CheckCircleOutlined className="success-icon" />
          </Col>
        </Row>
        <Title level={2} className="success-title">Payment Successful</Title>
        <Paragraph className="success-message">
          Thank you for your payment! Your transaction has been completed successfully.
        </Paragraph>
        <div className="transaction-details">
          <Text strong>Payment ID: </Text>
          <Text>{paymentId}</Text>
          <br />
          <Text strong>Order ID: </Text>
          <Text>{orderId}</Text>
          <br />
          <Text strong>Signature: </Text>
          <Text>{signature}</Text>
        </div>
        <Button type="primary" className="back-button" onClick={() => navigate('/')}>
          Go to Homepage
        </Button>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
