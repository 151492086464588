import React from 'react';
import { Layout, Title, Row, Col } from 'antd';
import BenefitsSection from './BenefitsSection';
import AppFooter from './Footer';
import './ContentLayout.css';
import CustomerReviews from './CustomerReviews';
const { Content } = Layout;

const ContentLayout = () => {
  return (
    <Layout className="content-layout">
      <Content className="home-content">
        <BenefitsSection />
      </Content>
          <CustomerReviews />
      <AppFooter />
    </Layout>
  );
};

export default ContentLayout;
