import React, { useState } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import {
  DashboardOutlined,
  CarOutlined,
  UserOutlined,
  BarChartOutlined,
  SettingOutlined,
  MenuOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import './AdminDashboard.css';
import AdminBookingsPage from './Bookings/AdminBookingsPage';
import AdminDriversPage from './Driver/AdminDriversPage';
import AdminVehiclesPage from './Vehicles/AdminVehiclesPage';
import AdminMarketingPage from './Marketing/AdminMarketingPage';
import SettingsPage from '../Settings/SettingsPage';
// import Navbar from '../Common/Navbar';
import CityAdminPage from './Website/CityAdminPage';
import { Web } from '@mui/icons-material';

const { Header, Sider, Content } = Layout;

const AdminDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('bookings');

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
    setDrawerVisible(false); // Close the drawer on menu item click
  };

  const handleLogout = () => {
    // Implement your logout logic here
    console.log("Logged out");
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case 'bookings':
        return <AdminBookingsPage />;
      case 'drivers':
        return <AdminDriversPage />;
      case 'vehicles':
        return <AdminVehiclesPage />;
      case 'marketing':
        return <AdminMarketingPage />;
      case 'website':
        return <CityAdminPage />;
      case 'settings':
        return <SettingsPage />;
      default:
        return <AdminBookingsPage />;
    }
  };

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Header className="site-layout-background" style={{ padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            type="primary"
            icon={<MenuOutlined />}
            onClick={() => setDrawerVisible(true)}
            style={{ marginLeft: '16px', display: 'flex', alignItems: 'center' }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="logo">TaxiPro</div>
            <Button
              type="link"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              style={{ color: 'white', marginLeft: '16px' }}
            >
              Logout
            </Button>
          </div>
        </Header>
        <Layout className="site-layout">
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            breakpoint="lg"
            className="sider"
            width={200}
          >
            <Menu theme="dark" defaultSelectedKeys={['bookings']} mode="inline" onClick={handleMenuClick}>
              <Menu.Item key="bookings" icon={<DashboardOutlined />}>
                Bookings
              </Menu.Item>
              <Menu.Item key="drivers" icon={<UserOutlined />}>
                Drivers
              </Menu.Item>
              <Menu.Item key="vehicles" icon={<CarOutlined />}>
                Vehicles
              </Menu.Item>
              <Menu.Item key="marketing" icon={<BarChartOutlined />}>
                Marketing
              </Menu.Item>

              <Menu.Item key="website" icon={<Web />}>
                Website
              </Menu.Item>

              <Menu.Item key="settings" icon={<SettingOutlined />}>
                Settings
              </Menu.Item>
            </Menu>
          </Sider>
          <Content style={{ margin: '16px' }}>
            {renderContent()}
          </Content>
        </Layout>
        <Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu theme="dark" mode="inline" onClick={handleMenuClick} selectedKeys={[selectedMenu]}>
            <Menu.Item key="bookings" icon={<DashboardOutlined />}>
              Bookings
            </Menu.Item>
            <Menu.Item key="drivers" icon={<UserOutlined />}>
              Drivers
            </Menu.Item>
            <Menu.Item key="vehicles" icon={<CarOutlined />}>
              Vehicles
            </Menu.Item>
            <Menu.Item key="marketing" icon={<BarChartOutlined />}>
              Marketing
            </Menu.Item>
            <Menu.Item key="settings" icon={<SettingOutlined />}>
              Settings
            </Menu.Item>
          </Menu>
        </Drawer>
      </Layout>
    </>
  );
};

export default AdminDashboard;
