import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import './AboutUs.css';
import Navbar from '../Common/Navbar';

const { Title, Paragraph } = Typography;

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className="about-us-container">
        <Row gutter={[16, 16]} justify="center">
          <Col span={24}>
            <Title level={1} className="about-us-title">About Us</Title>
          </Col>
          <Col span={24}>
            <Card className="about-us-card">
              <Paragraph>
                Welcome to CityTaxis, your innovative ride service provider dedicated to transforming city transportation. Our mission is to leverage cutting-edge technology to offer safe, efficient, and sustainable transportation solutions.
              </Paragraph>
              <Paragraph>
                Our platform seamlessly integrates with city infrastructure to provide a connected, user-friendly service that supports both daily commutes and leisure travelers. With a focus on customer satisfaction, we employ a fleet of eco-friendly vehicles and trained professionals.
              </Paragraph>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="about-us-team">
          <Col span={24}>
            <Title level={2} className="about-us-subtitle">Meet Our Leaders</Title>
          </Col>
          {["John Doe - CEO", "Jane Smith - COO", "Bob Johnson - CTO"].map((member, index) => (
            <Col key={index} xs={24} sm={12} lg={8}>
              <Card className="team-member-card">
                <div className="team-member-image">
                  <img src={`https://via.placeholder.com/150?text=${member.split(' - ')[0]}`} alt={member.split(' - ')[0]} />
                </div>
                <Title level={4}>{member.split(' - ')[0]}</Title>
                <Paragraph>{member.split(' - ')[1]}</Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default AboutUs;
