// components/ProtectedRoute.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { loginSuccess, logout } from '../../redux/authSlice';

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const loginTime = localStorage.getItem('loginTime');

    if (token && loginTime) {
      const elapsed = Date.now() - parseInt(loginTime, 10);
      const sessionDuration = 1800 * 1000; // 30 minute session duration

      if (elapsed <= sessionDuration) {
        // If token is valid and session has not expired
        dispatch(loginSuccess(token));
      } else {
        // If session has expired
        dispatch(logout());
        localStorage.removeItem('authToken');
        localStorage.removeItem('loginTime');
      }
    } else {
      // No token found
      dispatch(logout());
    }
  }, [dispatch]);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
