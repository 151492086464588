import React, { useState, useEffect, useRef } from 'react';
import { List, Avatar, Input, Button, Layout, Typography, Card, Divider, Modal, message, Space } from 'antd';
import { SendOutlined, UserOutlined, InfoCircleOutlined, MessageOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { getAllBookings, sendTextMessage, sendWhatsAppMessage } from '../../../services/api';
import './MessagingPage.css';

const { Sider, Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

const MessagingPage = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const fetchedCustomers = await getAllBookings();
        console.log('Fetched customers:', fetchedCustomers); // Debugging line to verify data
        
        // Using a Map to ensure uniqueness by phone number
        const uniqueCustomersMap = new Map();
        fetchedCustomers.forEach((customer) => {
          if (!uniqueCustomersMap.has(customer.phoneNumber)) {
            uniqueCustomersMap.set(customer.phoneNumber, customer);
          }
        });

        // Convert map values to an array to set as state
        const uniqueCustomersArray = Array.from(uniqueCustomersMap.values());
        console.log('Unique customers:', uniqueCustomersArray); // Debugging line to verify uniqueness
        setCustomers(uniqueCustomersArray);
      } catch (err) {
        console.error('Failed to fetch customers:', err.message);
      }
    };

    fetchCustomers();
  }, []);

  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [messages]);

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    // Fetch messages for the selected customer
    // Placeholder for fetching message history from the backend
    const messageHistory = [
      { id: 1, content: 'Hello!', timestamp: new Date(), sender: 'customer' },
      { id: 2, content: 'Hi, how can I help you?', timestamp: new Date(), sender: 'agent' },
    ];
    setMessages(messageHistory);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedCustomer) {
      try {
        console.log("Sending message with: ", selectedCustomer.phoneNumber, newMessage);
        const sendTextMessageRequest = {
          messageContent: newMessage,
          to: "91" + selectedCustomer.phoneNumber
        };
        await sendTextMessage(sendTextMessageRequest);
        const messageObj = {
          id: Date.now(),
          content: newMessage,
          timestamp: new Date(),
          sender: 'agent',
        };
        setMessages([...messages, messageObj]);
        setNewMessage('');
        message.success('Message sent successfully!');
      } catch (error) {
        message.error('Failed to send message');
        console.error(error);
      }
    }
  };

  const handleRequestLocation = async () => {
    if (selectedCustomer) {
      try {
        const requestLocationMessage = {
          messageType: 'interactive',
          messageContent: {
            type: 'location_request_message',
            body: {
              text: "Let's start with your pickup. You can either manually enter an address or share your current location."
            },
            action: {
              name: 'send_location'
            }
          },
          to: "91" + selectedCustomer.phoneNumber
        };

        await sendWhatsAppMessage(requestLocationMessage);

        const messageObj = {
          id: Date.now(),
          content: 'Location request sent.',
          timestamp: new Date(),
          sender: 'agent',
        };

        setMessages((prevMessages) => [...prevMessages, messageObj]);
        message.success('Location request sent successfully!');
      } catch (error) {
        message.error('Failed to send location request');
        console.error('Error sending location request:', error);
      }
    }
  };

  const handleCreateMessage = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout className="messaging-page">
      <Sider width={280} className="messaging-sider">
        <Title level={4} style={{ padding: '16px', color: '#fff', textAlign: 'center' }}>
          Customers
        </Title>
        <List
          itemLayout="horizontal"
          dataSource={customers}
          renderItem={(customer) => (
            <List.Item onClick={() => handleSelectCustomer(customer)} className={`customer-item ${selectedCustomer && selectedCustomer.phoneNumber === customer.phoneNumber ? 'active' : ''}`}>
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} src={customer.avatar} />}
                title={<Text style={{ color: '#fff' }}>{customer.customerName}</Text>}
                description={<Text style={{ color: '#fff' }}>{customer.phoneNumber}</Text>}
              />
            </List.Item>
          )}
        />
      </Sider>
      <Layout>
        <Content className="chat-content">
          <div className="chat-header">
            <Title level={4}>{selectedCustomer ? selectedCustomer.customerName : 'Select a customer'}</Title>
            <Space>
              <Button type="primary" icon={<MessageOutlined />} onClick={handleCreateMessage} disabled={!selectedCustomer}>
                New Message
              </Button>
              <Button icon={<EnvironmentOutlined />} onClick={handleRequestLocation} disabled={!selectedCustomer}>
                Request Location
              </Button>
              {selectedCustomer && (
                <Button icon={<InfoCircleOutlined />} onClick={() => setShowDetails(!showDetails)}>
                  {showDetails ? 'Hide Details' : 'Show Details'}
                </Button>
              )}
            </Space>
            {showDetails && selectedCustomer && (
              <Card className="customer-details" bordered={false}>
                <Text><strong>Email:</strong> {selectedCustomer.email}</Text>
                <br />
                <Text><strong>Phone:</strong> {selectedCustomer.phoneNumber}</Text>
                <br />
                <Text><strong>Last Booking:</strong> {selectedCustomer.lastBooking}</Text>
              </Card>
            )}
          </div>
          <Divider />
          <div className="chat-messages">
            {messages.map((message) => (
              <div key={message.id} className={`message ${message.sender}`}>
                <div className={`message-bubble ${message.sender}`}>
                  <p>{message.content}</p>
                  <span className="timestamp">{new Date(message.timestamp).toLocaleTimeString()}</span>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <Divider />
          <div className="chat-input">
            <TextArea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              rows={2}
              placeholder="Type your message..."
              disabled={!selectedCustomer}
              style={{ borderRadius: '8px', resize: 'none', minHeight: '60px' }}
            />
            <Button type="primary" icon={<SendOutlined />} onClick={handleSendMessage} disabled={!selectedCustomer} style={{ marginLeft: '10px' }}>
              Send
            </Button>
          </div>
        </Content>
      </Layout>

      <Modal title="Create New Message" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <TextArea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          rows={4}
          placeholder="Type your message..."
        />
        <Button type="primary" icon={<SendOutlined />} onClick={handleSendMessage} style={{ marginTop: '10px' }}>
          Send
        </Button>
      </Modal>
    </Layout>
  );
};

export default MessagingPage;
