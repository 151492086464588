import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Customer/Home';
import Login from './components/Common/Login';
import BookingDetailsPage from './components/Customer/BookingDetailsPage';
import PaymentSuccess from './components/Customer/PaymentSuccess';
import PaymentFailure from './components/Customer/PaymentFailure';
import AboutUs from './components/Customer/AboutUs';
import AdminDashboard from './components/Admin/AdminDashboard';
import AdminDriversPage from './components/Admin/Driver/AdminDriversPage';
import WhatsAppProviderHomePage from './components/Admin/Marketing/WhatsappSignupPage';
import BillPage from './components/Admin/Bookings/BillPage';
import WhatsAppDashboard from './components/Admin/Marketing/AdminWhatsAppDashBoard';
import CreateCampaignPage from './components/Admin/Marketing/CreateCampaignPage';
import MessagingPage from './components/Admin/Marketing/MessagingPage';
import TemplateManagementPage from './components/Admin/Marketing/TemplateManagementPage';
import CreateTemplatePage from './components/Admin/Marketing/CreateTemplatePage';
import LandingPage from './pages/LandingPage';
import Register from './components/Common/Register';
import ProtectedRoute from './components/Common/ProtectedRoute';
import PricingPage from './pages/PricingPage';
import ContactUs from './pages/ContactUs';
import DemoPage from './pages/Demo';



function App() {
  return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/demo" element={<DemoPage />} />

        {/* Protected routes with AppLayout */}
        {/* <Route element={<AppLayout />}> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/book" element={<Home />} />
          <Route path="/booking-details" element={<BookingDetailsPage />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />
          <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />          <Route path="/admin/driver" element={<AdminDriversPage />} />
          <Route path="/whatsapp" element={<WhatsAppProviderHomePage />} />
          <Route path="/bill/:id" element={<BillPage />} />
          <Route path="/whatsapp-dashboard" element={<WhatsAppDashboard />} />
          <Route path="/whatsapp-campaign" element={<CreateCampaignPage />} />
          <Route path="/whatsapp-messages" element={<MessagingPage />} />
          <Route path="/whatsapp-templates" element={<TemplateManagementPage />} />
          <Route path="/create-campaign" element={<CreateCampaignPage />} />
          <Route path="/create-template" element={<CreateTemplatePage/>} />
      </Routes>
  );
}

export default App;
