import React from 'react';
import { Button, Typography, Row, Col, Card } from 'antd';
import './Demo.css';
import Navbar from '../components/Common/Navbar';
import Footer from '../components/Common/Footer';

const { Title, Paragraph } = Typography;

const DemoPage = () => {
  return (
    <>
      <Navbar />
      <div className="demo-container">
        <Row justify="center" className="demo-header">
          <Col span={24}>
            <Title level={1} className="demo-title">Experience Our Platform</Title>
            <Paragraph className="demo-subtitle">Explore how TaxiPro can streamline your transportation business with cutting-edge technology.</Paragraph>
          </Col>
        </Row>
        <Row gutter={[32, 32]} className="feature-section">
          {["Automated Dispatch", "Real-Time Tracking", "Efficient Routing"].map((feature, index) => (
            <Col key={index} xs={24} sm={12} lg={8}>
              <Card hoverable className="feature-card">
                <Title level={3}>{feature}</Title>
                <Paragraph>Discover the power of {feature.toLowerCase()} and how it improves operational efficiency and customer satisfaction.</Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
        <div className="call-to-action">
          <Button type="primary" size="large" className="demo-cta-button">Start Free Trial</Button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DemoPage;
