import React from 'react';
import { Layout, Typography, Row, Col, Space } from 'antd';
import './Home.css';

const { Footer } = Layout;
const { Title, Paragraph, Link } = Typography;

const AppFooter = () => {
  return (
    <Footer className="home-footer">
      <div className="footer-content">
        <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6} className="footer-content">
            <Title level={5} className="footer-title">Company</Title>
            <Space direction="vertical">
              <Link href="#about">About Us</Link>
              <Link href="#team">Team</Link>
              <Link href="#careers">Careers</Link>
              <Link href="#contact">Contact</Link>
            </Space>
          </Col>
          <Col xs={24} sm={12} md={6} className="footer-content">
            <Title level={5} className="footer-title">Support</Title>
            <Space direction="vertical">
              <Link href="#faq">FAQ</Link>
              <Link href="#help">Help Center</Link>
              <Link href="#terms">Terms of Service</Link>
              <Link href="#privacy">Privacy Policy</Link>
            </Space>
          </Col>
          <Col xs={24} sm={12} md={6} className="footer-content">
            <Title level={5} className="footer-title">Follow Us</Title>
            <Space direction="vertical">
              <Link href="#facebook">Facebook</Link>
              <Link href="#twitter">Twitter</Link>
              <Link href="#instagram">Instagram</Link>
              <Link href="#linkedin">LinkedIn</Link>
            </Space>
          </Col>

          
          <Col xs={24} sm={12} md={6} className="footer-content">
            <Title level={5} className="footer-title"> Contact Us</Title>
            <Paragraph>
              Email: <Link href="mailto:anand@citytaxis.in">anand@citytaxis.in</Link><br />
              Phone: <Link href="tel:+91-9798642711">+91-9798642711</Link><br />
              Address: Sector: 4D, Bokaro Steel City
            </Paragraph>
          </Col>
        </Row>
        <Row justify="center" className="footer-bottom">
          <Col>
            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
              &copy; {new Date().getFullYear()} CityTaxis. All rights reserved.
            </Paragraph>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default AppFooter;
