import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Space, Card, Select, Row, Col, Modal, Typography, Form, message, Checkbox, Tabs } from 'antd';
import { EyeOutlined, PlusOutlined, SendOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { fetchTemplates, sendTemplateToCustomer, createTemplate, sendTemplateToAllUsers } from '../../../services/api';
import SendTemplateModal from './SendTemplateModal'; // Import your SendTemplateModal component

const { Option } = Select;
const { Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const TemplateManagementPage = () => {
  const [templates, setTemplates] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [favorites, setFavorites] = useState([]);
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const templatesData = await fetchTemplates();
        setTemplates(templatesData);
      } catch (err) {
        console.error('Failed to fetch templates:', err);
      }
    };

    fetchData();
  }, []);

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setPreviewVisible(true);
  };

  const handleCreateTemplate = async (values) => {
    try {
      const newTemplate = await createTemplate(values);
      setTemplates([...templates, newTemplate]);
      setCreateVisible(false);
      message.success('Template created successfully');
    } catch (err) {
      console.error('Failed to create template:', err);
      message.error('Failed to create template');
    }
  };

  const handleSendTemplate = (template) => {
    setSelectedTemplate(template);
    setSendModalVisible(true);
  };

  const handleBulkSend = async (template) => {
    try {
      await sendTemplateToAllUsers(template); // Implement this API call in your backend
      message.success('Template sent to all users successfully');
    } catch (err) {
      console.error('Failed to send template:', err);
      message.error('Failed to send template to all users');
    }
  };

  const toggleFavorite = (templateId) => {
    setFavorites(prev => prev.includes(templateId)
      ? prev.filter(id => id !== templateId)
      : [...prev, templateId]);
  };

  const renderTemplatePreview = (template) => {
    if (!template) return null;

    return (
      <div className="whatsapp-preview">
        {template.components.map((component, index) => (
          <div key={index} className="component-section">
            <Paragraph>
              {component.type === 'HEADER' && component.format === 'IMAGE' &&
                <img src={component.example.header_handle[0]} alt="Header" className="header-image" />}
              {component.type === 'BODY' &&
                <Text>{component.text}</Text>}
              {component.type === 'BUTTONS' && component.buttons.map((button, btnIndex) => (
                <Button key={btnIndex} type="primary" style={{ margin: '5px' }}>
                  {button.text}
                </Button>
              ))}
            </Paragraph>
          </div>
        ))}
      </div>
    );
  };

  const filteredTemplates = templates.filter(template =>
    template.name.toLowerCase().includes(searchText.toLowerCase()) &&
    (filterCategory === 'all' || template.category === filterCategory) &&
    (activeTab === 'favorites' ? favorites.includes(template.id) : true)
  );

  const columns = [
    { title: 'Template Name', dataIndex: 'name', key: 'name' },
    { title: 'Category', dataIndex: 'category', key: 'category' },
    { title: 'Language', dataIndex: 'language', key: 'language' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => handlePreview(record)}
          >
            Preview
          </Button>
          <Button
            type="link"
            icon={<SendOutlined />}
            onClick={() => handleSendTemplate(record)}
          >
            Send
          </Button>
          <Button
            type="link"
            icon={favorites.includes(record.id) ? <StarFilled /> : <StarOutlined />}
            onClick={() => toggleFavorite(record.id)}
          >
            Favorite
          </Button>
          <Button
            type="link"
            icon={<SendOutlined />}
            onClick={() => handleBulkSend(record)}
            danger
          >
            Bulk Send
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="template-management-page">
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="All Templates" key="all">
          <Row gutter={16}>
            <Col span={24}>
              <Card className="search-card">
                <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                  <Input
                    placeholder="Search templates"
                    onChange={e => setSearchText(e.target.value)}
                    style={{ width: 200 }}
                  />
                  <Select defaultValue="all" onChange={setFilterCategory} style={{ width: 120 }}>
                    <Option value="all">All Categories</Option>
                    {Array.from(new Set(templates.map(item => item.category))).map(category => (
                      <Option key={category} value={category}>{category}</Option>
                    ))}
                  </Select>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setCreateVisible(true)}
                  >
                    Create Template
                  </Button>
                </Space>
                <Table
                  columns={columns}
                  dataSource={filteredTemplates}
                  rowKey="id"
                />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Favorites" key="favorites">
          <Row gutter={16}>
            <Col span={24}>
              <Card className="search-card">
                <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                  <Input
                    placeholder="Search templates"
                    onChange={e => setSearchText(e.target.value)}
                    style={{ width: 200 }}
                  />
                </Space>
                <Table
                  columns={columns}
                  dataSource={filteredTemplates}
                  rowKey="id"
                />
              </Card>
            </Col>
          </Row>
        </TabPane>
      </Tabs>

      <Modal
        title="Template Preview"
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
        width={340}
      >
        {selectedTemplate && renderTemplatePreview(selectedTemplate)}
      </Modal>

      {selectedTemplate && (
        <SendTemplateModal
          watemplate={selectedTemplate} // Corrected the prop name to match
          isVisible={sendModalVisible}
          onClose={() => setSendModalVisible(false)}
        />
      )}

      <Modal
        title="Create New Template"
        visible={createVisible}
        onCancel={() => setCreateVisible(false)}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleCreateTemplate}>
          <Form.Item label="Template Name" name="name" rules={[{ required: true, message: 'Please enter a template name' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Category" name="category" rules={[{ required: true, message: 'Please select a category' }]}>
            <Select>
              <Option value="Promotional">Promotional</Option>
              <Option value="Transactional">Transactional</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Language" name="language" rules={[{ required: true, message: 'Please select a language' }]}>
            <Select>
              <Option value="English">English</Option>
              <Option value="Spanish">Spanish</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Body Text" name="bodyText" rules={[{ required: true, message: 'Please enter body text' }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Template
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TemplateManagementPage;
