import React from 'react';
import { Row, Col, Typography, Card } from 'antd';

const { Title, Paragraph } = Typography;

const benefits = [
  {
    title: 'Safety',
    description: 'Your safety is our top priority. All our drivers are trained professionals, and our vehicles are regularly inspected to ensure they meet safety standards.',
  },
  {
    title: 'Convenience',
    description: 'Book a ride with just a few clicks. Our app is user-friendly and allows you to book a ride at your convenience.',
  },
  {
    title: 'Reliability',
    description: 'We provide reliable service with timely pickups and drop-offs. Track your ride in real-time and get updates on your driver\'s location.',
  },
];

const BenefitsSection = () => {
  return (
    <div className="benefits-section">
      <Title level={3} className='benefits-title'>Why Choose Us?</Title>
      <Row gutter={[16, 16]} justify="center">
        {benefits.map((benefit, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card hoverable className="benefit-card">
              <Title level={4}>{benefit.title}</Title>
              <Paragraph>{benefit.description}</Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BenefitsSection;
