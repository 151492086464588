import React, { useState } from 'react';
import { Form, Input, Button, Typography, Row, Col, Card, Select } from 'antd';
import { MailOutlined, PhoneOutlined, EnvironmentOutlined, WhatsAppOutlined } from '@ant-design/icons';
import Navbar from '../components/Common/Navbar';
import Footer from '../components/Common/Footer';
import './ContactUs.css';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const ContactUs = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);
        console.log('Received values of form: ', values);
        setTimeout(() => {
            setLoading(false);
            form.resetFields();
            alert("Thank you for contacting us!");
        }, 2000);
    };

    return (
        <div className="contact-page">
            <Navbar />
            <div className="contact-container">
                <Row gutter={[24, 24]} justify="center">
                    <Col xs={24} md={12}>
                        <Card className="contact-form-card" bordered={false}>
                            <Title level={2} className="contact-title">Get in Touch</Title>
                            <Form form={form} layout="vertical" onFinish={onFinish}>
                                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
                                    <Input placeholder="Enter your name" />
                                </Form.Item>
                                <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
                                    <Input placeholder="Enter your email" />
                                </Form.Item>
                                <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input your phone number!' }]}>
                                    <Input placeholder="Enter your phone number" />
                                </Form.Item>
                                <Form.Item name="subject" label="Subject">
                                    <Input placeholder="Enter subject" />
                                </Form.Item>
                                <Form.Item name="department" label="Department">
                                    <Select placeholder="Select a department">
                                        <Option value="sales">Sales</Option>
                                        <Option value="support">Support</Option>
                                        <Option value="general">General Inquiry</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please input your message!' }]}>
                                    <Input.TextArea rows={4} placeholder="Type your message here" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading}>Send Message</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card className="contact-info-card" bordered={false}>
                            <Title level={2} className="contact-title">Contact Information</Title>
                            <Paragraph><MailOutlined /> email@example.com</Paragraph>
                            <Paragraph><PhoneOutlined /> <a href="tel:+1234567890">+123 456 7890</a></Paragraph>
                            <Paragraph><WhatsAppOutlined /> <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">Chat with us on WhatsApp</a></Paragraph>
                            <Paragraph><EnvironmentOutlined /> 123 Example Street, City, Country</Paragraph>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
