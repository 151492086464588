// Section.js
import React from 'react';
import { Row, Col, Typography } from 'antd';
import './Section.css';

const { Title, Paragraph } = Typography;

const Section = ({ title, description, icon }) => {
  return (
    <div className="section">
      <Row gutter={[32, 32]} align="middle">
        <Col xs={24} md={6} className="section-icon">
          {icon}
        </Col>
        <Col xs={24} md={18}>
          <Title level={3}>{title}</Title>
          <Paragraph>{description}</Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default Section;
