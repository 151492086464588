import React, { useState, useEffect } from 'react';
import {
  Table,
  Spin,
  Alert,
  Button,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Card,
  Row,
  Col,
  Space,
  Typography,
  Rate,
  Avatar,
} from 'antd';
import {
  getAllDrivers,
  addDriver,
  updateDriver,
  deleteDriver,
  getAllVehicles,
} from '../../../services/api';
import {
  FileExcelOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import '../AdminCommon.css';

const { Option } = Select;
const { Title } = Typography;

const AdminDriversPage = () => {
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    fetchDrivers();
    fetchVehicles();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchDrivers = async () => {
    try {
      setLoading(true);
      const fetchedDrivers = await getAllDrivers();
      setDrivers(fetchedDrivers);
    } catch (err) {
      setError('Failed to load drivers. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicles = async () => {
    try {
      const fetchedVehicles = await getAllVehicles();
      setVehicles(fetchedVehicles);
    } catch (err) {
      setError('Failed to load vehicles. Please try again later.');
    }
  };

  const handleAddDriver = () => {
    setIsEditing(false);
    setSelectedDriver(null);
    setModalVisible(true);
  };

  const handleEditDriver = (driver) => {
    setIsEditing(true);
    setSelectedDriver(driver);
    setModalVisible(true);
  };

  const handleDeleteDriver = async (driverId) => {
    try {
      await deleteDriver(driverId);
      message.success('Driver deleted successfully');
      fetchDrivers();
    } catch (err) {
      setError('Error deleting driver. Please try again later.');
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isEditing) {
        await updateDriver(selectedDriver._id, values);
        message.success('Driver updated successfully');
      } else {
        await addDriver(values);
        message.success('Driver added successfully');
      }
      fetchDrivers();
      setModalVisible(false);
    } catch (err) {
      setError('Error saving driver. Please try again later.');
      message.error('Error saving driver');
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredDrivers = drivers.filter(
    (driver) =>
      driver.name.toLowerCase().includes(searchText.toLowerCase()) ||
      driver.phoneNumber.includes(searchText) ||
      driver.licenseNumber.includes(searchText) ||
      driver.panCardNumber.includes(searchText) ||
      driver.aadharCardNumber.includes(searchText)
  );

  const exportToCSV = () => {
    message.info('Exporting to CSV...');
    // Implement CSV export logic here
  };

  if (loading) return <Spin tip="Loading..." />;
  if (error)
    return <Alert message="Error" description={error} type="error" showIcon />;

  const columns = [
    {
      title: 'Driver',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Space>
          <Avatar icon={<UserOutlined />} src={record.profileImage} alt={name} />
          {name}
        </Space>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'License',
      dataIndex: 'licenseNumber',
      key: 'licenseNumber',
    },
    {
      title: 'PAN',
      dataIndex: 'panCardNumber',
      key: 'panCardNumber',
    },
    {
      title: 'Aadhar',
      dataIndex: 'aadharCardNumber',
      key: 'aadharCardNumber',
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (rating) => <Rate disabled defaultValue={rating} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status}
          onChange={(newStatus) =>
            updateDriver(record._id, { status: newStatus })
          }
          style={{ width: 120 }}
        >
          <Option value="available">Available</Option>
          <Option value="on_trip">On Trip</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditDriver(record)} aria-label="Edit Driver">
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this driver?"
            onConfirm={() => handleDeleteDriver(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger aria-label="Delete Driver">
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const renderCardView = () => (
    <div className="card-container">
      {filteredDrivers.map((driver) => (
        <Card key={driver._id} className="driver-card">
          <Space>
            <Avatar
              icon={<UserOutlined />}
              src={driver.profileImage}
              alt={driver.name}
              size={48}
            />
            <div>
              <p>
                <strong>{driver.name}</strong>
              </p>
              <p>{driver.phoneNumber}</p>
              <Rate disabled defaultValue={driver.rating} />
            </div>
          </Space>
          <div>
            <p>
              <strong>License:</strong> {driver.licenseNumber}
            </p>
            <p>
              <strong>PAN:</strong> {driver.panCardNumber}
            </p>
            <p>
              <strong>Aadhar:</strong> {driver.aadharCardNumber}
            </p>
            <Select
              value={driver.status}
              onChange={(newStatus) => updateDriver(driver._id, { status: newStatus })}
              style={{ width: 120, marginBottom: 8 }}
            >
              <Option value="available">Available</Option>
              <Option value="on_trip">On Trip</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </div>
          <Space size="middle" className="actions-column">
            <Button onClick={() => handleEditDriver(driver)} aria-label="Edit Driver">
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to delete this driver?"
              onConfirm={() => handleDeleteDriver(driver._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger aria-label="Delete Driver">
                Delete
              </Button>
            </Popconfirm>
          </Space>
        </Card>
      ))}
    </div>
  );

  return (
    <div className="admin-page">
      <Row justify="space-between" align="middle" className="admin-header">
        <Col>
          <Title level={3}>Driver Management</Title>
        </Col>
        <Col level={3}>
            <Button
              type="primary"
              onClick={handleAddDriver}
              icon={<PlusOutlined />}
            >
              Add Driver
            </Button>
            <Button icon={<FileExcelOutlined />} onClick={exportToCSV}>
              Export CSV
            </Button>
            <Input.Search
              placeholder="Search drivers"
              value={searchText}
              onChange={handleSearch}
              onSearch={handleSearch}
              style={{ width: '100%' }}
              allowClear
              enterButton
            />
        </Col>
      </Row>
      {isMobile ? (
        renderCardView()
      ) : (
        <Table dataSource={filteredDrivers} columns={columns} rowKey="_id" />
      )}
      <Modal
        title={isEditing ? 'Edit Driver' : 'Add Driver'}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleSubmit} initialValues={selectedDriver}>
          <Form.Item
            label="Driver Name"
            name="name"
            rules={[{ required: true, message: 'Please input the driver name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: 'Please input the phone number!' },
              { pattern: /^\d{10}$/, message: 'Phone number must be 10 digits!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="License Number"
            name="licenseNumber"
            rules={[{ required: true, message: 'Please input the license number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="PAN Card Number"
            name="panCardNumber"
            rules={[{ required: true, message: 'Please input the PAN card number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Aadhar Card Number"
            name="aadharCardNumber"
            rules={[{ required: true, message: 'Please input the Aadhar card number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Assigned Vehicles" name="vehicles">
            <Select mode="multiple" placeholder="Select vehicles">
              {vehicles.map((vehicle) => (
                <Option key={vehicle._id} value={vehicle._id}>
                  {vehicle.licensePlate} ({vehicle.licensePlateColor})
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Rating"
            name="rating"
            rules={[{ required: true, message: 'Please provide a rating!' }]}
          >
            <Rate />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isEditing ? 'Update Driver' : 'Add Driver'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminDriversPage;
