import React from 'react';
import { Row, Col, Card, Statistic } from 'antd';
import { MessageOutlined, SendOutlined, AppstoreAddOutlined, UserOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/charts';

const WhatsAppDashboardHome = () => {
  // Sample data for the charts
  const messageData = [
    { date: '2024-08-01', value: 120 },
    { date: '2024-08-02', value: 200 },
    { date: '2024-08-03', value: 150 },
    { date: '2024-08-04', value: 300 },
    { date: '2024-08-05', value: 250 },
  ];

  const config = {
    data: messageData,
    xField: 'date',
    yField: 'value',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
    smooth: true,
    lineStyle: {
      lineWidth: 3,
    },
    height: 300,
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Messages Sent"
              value={1024}
              valueStyle={{ color: '#3f8600' }}
              prefix={<MessageOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Active Campaigns"
              value={15}
              valueStyle={{ color: '#cf1322' }}
              prefix={<SendOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Templates Used"
              value={58}
              valueStyle={{ color: '#3f8600' }}
              prefix={<AppstoreAddOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="New Users"
              value={34}
              valueStyle={{ color: '#3f8600' }}
              prefix={<UserOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Card>
            <h3>Messages Sent Over Time</h3>
            <Line {...config} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default WhatsAppDashboardHome;
