import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Spin, Alert, Button, Modal, Form, Input, notification, Tag, Row, Col, Card, Statistic, Select } from 'antd';
import { getAllBookings, updateBookingById } from '../../../services/api';
import EditModal from '../../Customer/EditModal';
import './AdminBookingsPage.css';

const { Option } = Select;

const statusColors = {
  Pending: 'orange',
  Booked: 'blue',
  'Driver Assigned': 'geekblue',
  'Trip Started': 'cyan',
  'Trip Completed': 'green',
  'Trip Canceled': 'volcano'
};

const AdminBookingsPage = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [assignDriverModalVisible, setAssignDriverModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const fetchedBookings = await getAllBookings();
        setBookings(fetchedBookings);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAssignDriver = (booking) => {
    setSelectedBooking(booking);
    setAssignDriverModalVisible(true);
  };

  const handleAssignDriverSubmit = async (values) => {
    try {
      await updateBookingById(selectedBooking._id, { assignedDriver: values });
      setAssignDriverModalVisible(false);
      setSelectedBooking(null);
      const updatedBookings = await getAllBookings();
      setBookings(updatedBookings);
      notification.success({
        message: 'Driver Assigned',
        description: 'The driver has been successfully assigned to the booking.'
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGenerateReceipt = (booking) => {
    const bookingId = booking._id;
    navigate(`/bill/${bookingId}`);
  };

  const handleEditBooking = (booking) => {
    setSelectedBooking(booking);
    setEditModalVisible(true);
  };

  const handleSaveBooking = async (values) => {
    try {
      await updateBookingById(selectedBooking._id, values);
      setEditModalVisible(false);
      setSelectedBooking(null);
      const updatedBookings = await getAllBookings();
      setBookings(updatedBookings);
      notification.success({
        message: 'Booking Updated',
        description: 'The booking details have been successfully updated.'
      });
    } catch (err) {
      setError(err.message);
      notification.error({
        message: 'Update Failed',
        description: err.message,
      });
    }
  };

  const handleFiltersChange = (changedFilters) => {
    setFilters({ ...filters, ...changedFilters });
  };

  const filteredBookings = bookings.filter((booking) => {
    return (
      (filters.pickupLocation ? filters.pickupLocation.includes(booking.pickupLocation) : true) &&
      (filters.dropLocation ? filters.dropLocation.includes(booking.dropLocation) : true) &&
      (filters.status ? filters.status.includes(booking.status) : true) &&
      (searchTerm ? booking.phoneNumber.includes(searchTerm) ||
        booking.pickupLocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.dropLocation.toLowerCase().includes(searchTerm.toLowerCase()) : true)
    );
  });

  const columns = [
    {
      title: 'Booking ID',
      dataIndex: '_id',
      key: 'id',
      sorter: (a, b) => a._id.localeCompare(b._id),
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    },
    {
      title: 'Pickup Location',
      dataIndex: 'pickupLocation',
      key: 'pickupLocation',
      sorter: (a, b) => a.pickupLocation.localeCompare(b.pickupLocation),
      filters: Array.from(new Set(bookings.map(booking => booking.pickupLocation))).map(pickupLocation => ({ text: pickupLocation, value: pickupLocation })),
      onFilter: (value, record) => record.pickupLocation === value,
    },
    {
      title: 'Drop Location',
      dataIndex: 'dropLocation',
      key: 'dropLocation',
      sorter: (a, b) => a.dropLocation.localeCompare(b.dropLocation),
      filters: Array.from(new Set(bookings.map(booking => booking.dropLocation))).map(dropLocation => ({ text: dropLocation, value: dropLocation })),
      onFilter: (value, record) => record.dropLocation === value,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'customerPhoneNumber',
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: 'Booking Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: Object.keys(statusColors).map(status => ({ text: status, value: status })),
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <Tag color={statusColors[status]}>{status}</Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="actions-column">
          <Button
            onClick={() => handleAssignDriver(record)}
            style={{
              background: record.assignedDriver ? 'green' : 'red',
              borderColor: record.assignedDriver ? 'green' : 'red',
              color: '#fff',
            }}
          >
            {record.assignedDriver ? 'Driver Assigned' : 'Assign Driver'}
          </Button>
          <Button onClick={() => handleGenerateReceipt(record)} style={{ marginLeft: 10 }}>
            Generate Receipt
          </Button>
          <Button onClick={() => handleEditBooking(record)} style={{ marginLeft: 10 }}>
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const renderCardView = () => (
    <div className="card-container">
      {filteredBookings.map((booking) => (
        <Card key={booking._id} className="booking-card">
          <p><strong>Booking ID:</strong> {booking._id}</p>
          <p><strong>Customer Name:</strong> {booking.customerName}</p>
          <p><strong>Pickup Location:</strong> {booking.pickupLocation}</p>
          <p><strong>Drop Location:</strong> {booking.dropLocation}</p>
          <p><strong>Phone Number:</strong> {booking.phoneNumber}</p>
          <p><strong>Booking Date:</strong> {new Date(booking.createdAt).toLocaleString()}</p>
          <p><strong>Status:</strong> <Tag color={statusColors[booking.status]}>{booking.status}</Tag></p>
          <div className="actions-column">
            <Button
              onClick={() => handleAssignDriver(booking)}
              style={{
                background: booking.assignedDriver ? 'green' : 'red',
                borderColor: booking.assignedDriver ? 'green' : 'red',
                color: '#fff',
              }}
            >
              {booking.assignedDriver ? 'Driver Assigned' : 'Assign Driver'}
            </Button>
            <Button onClick={() => handleGenerateReceipt(booking)} style={{ marginLeft: 10 }}>
              Generate Receipt
            </Button>
            <Button onClick={() => handleEditBooking(booking)} style={{ marginLeft: 10 }}>
              Edit
            </Button>
          </div>
        </Card>
      ))}
    </div>
  );

  if (loading) return <Spin tip="Loading..." />;
  if (error) return <Alert message="Error" description={error} type="error" showIcon />;

  return (
    <div className="admin-bookings-page">
      <div className="admin-bookings-header">
        <h1>All Bookings</h1>
        <Input
          placeholder="Search bookings"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
      </div>
      <Row gutter={16} className="admin-bookings-metrics">
        <Col span={8}>
          <Card>
            <Statistic title="Total Bookings" value={bookings.length} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Pending Bookings" value={bookings.filter(b => b.status === 'Pending').length} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Completed Bookings" value={bookings.filter(b => b.status === 'Trip Completed').length} />
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      {isMobile ? renderCardView() : <Table dataSource={filteredBookings} columns={columns} rowKey="_id" />}
      <Modal
        title="Assign Driver"
        visible={assignDriverModalVisible}
        onCancel={() => setAssignDriverModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleAssignDriverSubmit}>
          <Form.Item
            label="Driver Name"
            name="driverName"
            rules={[{ required: true, message: 'Please input the driver name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Vehicle Number"
            name="vehicleNumber"
            rules={[{ required: true, message: 'Please input the vehicle number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Driver Phone Number"
            name="driverPhoneNumber"
            rules={[{ required: true, message: 'Please input the driver phone number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Assign Driver
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <EditModal
        visible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        onSave={handleSaveBooking}
        booking={selectedBooking}
      />
    </div>
  );
};

export default AdminBookingsPage;
